import React, { useMemo, useState } from "react";
import Tooltip, { TooltipPlacement } from "components/tooltip/tooltip";
import { AccessControlKeys } from "utilities/enumerations/authorization/access-control-keys";
import { ButtonStyle } from "components/buttons/button/button";
import {
    CertificateService,
    DownloadCertificatePathParams,
    DownloadCertificateQueryParams,
} from "utilities/services/certificates/certificate-service";
import { CollectionUtils } from "utilities/collection-utils";
import { ContextMenu } from "components/context-menu/context-menu/context-menu";
import { ContextMenuButton } from "components/context-menu/context-menu-button/context-menu-button";
import { DataTable } from "components/tables/data-table/data-table";
import { EmptyText } from "components/empty-text/empty-text";
import {
    EnrollmentAttendanceStatus,
    EnrollmentAttendanceStatusDisplayNames,
    EnrollmentAttendanceStatusMap,
} from "models/enumerations/enrollments/enrollment-attendance-status";
import { EnrollmentRecord } from "models/view-models/enrollments/enrollment-record";
import {
    EnrollmentScormPackageAssessmentStatusDisplayNames,
    EnrollmentScormPackageAssessmentStatusMap,
} from "models/enumerations/enrollments/enrollment-scorm-package-assessment-status";
import { EventEnrollmentSummaryRecord } from "models/view-models/enrollments/event-enrollment-summary-record";
import { Icon } from "components/icons/icon";
import { Icons } from "components/icons/constants/icons";
import { ModalAction } from "components/modal/modal";
import { ProductEnrollmentSummaryRecord } from "models/view-models/enrollments/product-enrollment-summary-record";
import { RoleType } from "models/enumerations/users/role-type";
import { Status } from "utilities/enumerations/statuses";
import { StatusBadge } from "components/badges/status-badges/status-badge/status-badge";
import { StringUtils } from "utilities/string-utils";
import { WithdrawEnrollmentModal } from "../withdraw-enrollment-modal/withdraw-enrollment-modal";
import { t } from "utilities/localization/t";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHasAccess } from "utilities/hooks/aspects/authorization/use-has-access";
import { useProvider } from "utilities/contexts/use-provider-context";
import "./enrollment-summary-list.scss";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

interface EnrollmentSummaryListProps {
    disableForClientAdmin?: boolean;
    enrollmentSummaries?: EventEnrollmentSummaryRecord[] | ProductEnrollmentSummaryRecord[];
    handleWithdrawEnrollment: (enrollment: EnrollmentRecord) => Promise<boolean>;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const CSS_CLASS_NAME: string = "enrollment-summary-list";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const EnrollmentSummaryList: React.FC<EnrollmentSummaryListProps> = ({
    disableForClientAdmin,
    enrollmentSummaries,
    handleWithdrawEnrollment,
}): JSX.Element => {
    const [enrollmentToWithdraw, setEnrollmentToWithdraw] = useState<
        EventEnrollmentSummaryRecord | ProductEnrollmentSummaryRecord
    >(new EventEnrollmentSummaryRecord());
    const [showWithdrawEnrollmentConfirmationModal, setShowWithdrawEnrollmentConfirmationModal] =
        useState(false);
    const { record: globalState } = useGlobalState();
    const { record: provider } = useProvider();
    const userRole = useMemo(
        () => globalState?.currentIdentity?.role?.roleType,
        [globalState?.currentIdentity?.role?.roleType]
    );

    const canModifyEnrollments: boolean = useMemo(
        (): boolean =>
            userRole === RoleType.NfpaAdministrator ||
            userRole === RoleType.NfpaOperator ||
            (userRole === RoleType.AenAdministrator && provider?.isActive) ||
            userRole === RoleType.ClientAdmin,
        [provider?.isActive, userRole]
    );

    const canDownloadCertificates = useHasAccess(AccessControlKeys.CanDownloadCertificates);

    const currentUserIsInNfpaRole = globalState.currentIdentity?.isCurrentlyInNfpaRole() ?? false;

    const withdrawEnrollmentConfirmationActionArray: ModalAction[] = [
        {
            buttonText: t("cancel"),
            onClick: () => setShowWithdrawEnrollmentConfirmationModal(false),
            style: ButtonStyle.Secondary,
        },
        {
            buttonText: t("withdrawLearner"),
            onClick: () => {
                handleWithdrawEnrollment(enrollmentToWithdraw?.enrollment!);
                setShowWithdrawEnrollmentConfirmationModal(false);
            },
            style: ButtonStyle.Primary,
        },
    ];

    const withdrawEnrollment = (
        e: EventEnrollmentSummaryRecord | ProductEnrollmentSummaryRecord
    ) => {
        setShowWithdrawEnrollmentConfirmationModal(true);
        setEnrollmentToWithdraw(e);
    };

    const handleDownloadCertificateClick = async (
        enrollmentId?: number,
        certificateId?: number
    ) => {
        if (enrollmentId == null || certificateId == null) {
            return;
        }

        const pathParams: DownloadCertificatePathParams = {
            enrollmentId: enrollmentId,
            id: certificateId,
        };

        const queryParams: DownloadCertificateQueryParams = {
            overwriteExisting: false,
        };

        await CertificateService.downloadCertificate(pathParams, queryParams, "certificate");
    };

    return (
        <>
            {CollectionUtils.hasValues(enrollmentSummaries) ? (
                <DataTable cssClassName={CSS_CLASS_NAME}>
                    <thead>
                        <tr>
                            <th className="account">
                                {`${t("name")}`}{" "}
                                <Tooltip
                                    content={
                                        <>
                                            {t("enrollmentStatusWillAppearAsInvitePending")}
                                            <i>
                                                {t(
                                                    "noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining"
                                                )}
                                            </i>
                                        </>
                                    }
                                    placement={TooltipPlacement.Top}>
                                    <span>
                                        <Icon type={Icons.Information} />
                                    </span>
                                </Tooltip>
                            </th>
                            <th className="enrollment">{t("enrollment")}</th>
                            <th className="assessment">{t("assessment")}</th>
                            <th className="evaluation">{t("evaluation")}</th>
                            <th className="attendance">{t("attendance")}</th>
                            <th className="action">
                                <span className="sr-only">{t("action")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {enrollmentSummaries !== undefined &&
                            enrollmentSummaries.map(
                                (
                                    enrollmentSummary:
                                        | EventEnrollmentSummaryRecord
                                        | ProductEnrollmentSummaryRecord
                                ): JSX.Element => (
                                    <tr key={enrollmentSummary.enrollmentId}>
                                        <td className="account">
                                            <span>
                                                <div className={`${CSS_CLASS_NAME}__account-name`}>
                                                    {StringUtils.hasValue(
                                                        enrollmentSummary.user?.firstName
                                                    )
                                                        ? enrollmentSummary.user?.getFirstAndLastName()
                                                        : "- -"}
                                                </div>
                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {enrollmentSummary.user?.email}
                                                </div>

                                                <div className={`${CSS_CLASS_NAME}__account-email`}>
                                                    {currentUserIsInNfpaRole
                                                        ? enrollmentSummary.user?.id
                                                        : ""}
                                                </div>
                                            </span>
                                        </td>
                                        <td className="enrollment">
                                            {enrollmentSummary.enrollment?.withdrawnById != null ? (
                                                <StatusBadge
                                                    status={Status.Error}
                                                    text="withdrawn"
                                                />
                                            ) : (
                                                <StatusBadge
                                                    status={Status.Success}
                                                    text="enrolled"
                                                />
                                            )}
                                        </td>
                                        <td className="assessment">
                                            {enrollmentSummary.enrollment?.withdrawnById ==
                                                null && (
                                                <StatusBadge
                                                    status={
                                                        EnrollmentScormPackageAssessmentStatusMap[
                                                            enrollmentSummary.status!
                                                        ]
                                                    }
                                                    text={
                                                        EnrollmentScormPackageAssessmentStatusDisplayNames[
                                                            enrollmentSummary.status!
                                                        ]
                                                    }
                                                />
                                            )}
                                        </td>
                                        <td className="evaluation">
                                            {enrollmentSummary.enrollment?.withdrawnById ==
                                                null && (
                                                <>
                                                    {enrollmentSummary.enrollment?.evaluation?.id ==
                                                        null && (
                                                        <StatusBadge
                                                            status={Status.Default}
                                                            text="notStarted"
                                                        />
                                                    )}
                                                    {enrollmentSummary.enrollment?.evaluation?.id !=
                                                        null && (
                                                        <StatusBadge
                                                            status={Status.Success}
                                                            text="submitted"
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </td>
                                        {enrollmentSummary instanceof
                                            EventEnrollmentSummaryRecord &&
                                        enrollmentSummary.attendanceStatus != null &&
                                        enrollmentSummary.attendanceStatus !==
                                            EnrollmentAttendanceStatus.NotApplicable ? (
                                            <td className={"attendance-status"}>
                                                <div className="attendance-status-badge">
                                                    <StatusBadge
                                                        status={
                                                            EnrollmentAttendanceStatusMap[
                                                                enrollmentSummary.attendanceStatus
                                                            ]
                                                        }
                                                        text={
                                                            EnrollmentAttendanceStatusDisplayNames[
                                                                enrollmentSummary.attendanceStatus
                                                            ]
                                                        }
                                                    />
                                                </div>
                                                <div className="attendance-ratio">
                                                    {enrollmentSummary.enrollment?.withdrawnById ==
                                                        null &&
                                                        enrollmentSummary.enrollment?.getAttendanceSummary()}
                                                </div>
                                            </td>
                                        ) : (
                                            <td className={"attendance"}>
                                                {enrollmentSummary.enrollment?.withdrawnById ==
                                                    null &&
                                                    enrollmentSummary.enrollment?.getAttendanceSummary()}
                                            </td>
                                        )}
                                        <td className="action">
                                            {(canModifyEnrollments || canDownloadCertificates) && (
                                                <ContextMenu>
                                                    {canModifyEnrollments && (
                                                        <ContextMenuButton
                                                            displayName={t("withdraw")}
                                                            disabled={
                                                                enrollmentSummary.enrollment?.isComplete() ||
                                                                enrollmentSummary.enrollment
                                                                    ?.withdrawnById != null ||
                                                                disableForClientAdmin
                                                            }
                                                            onClick={() =>
                                                                withdrawEnrollment(
                                                                    enrollmentSummary
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    {canDownloadCertificates && (
                                                        <ContextMenuButton
                                                            displayName={t("downloadCertificate")}
                                                            disabled={
                                                                enrollmentSummary.enrollment
                                                                    ?.certificate == null
                                                            }
                                                            onClick={() =>
                                                                handleDownloadCertificateClick(
                                                                    enrollmentSummary.enrollment
                                                                        ?.id,
                                                                    enrollmentSummary.enrollment
                                                                        ?.certificate?.id
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </ContextMenu>
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                    </tbody>
                </DataTable>
            ) : (
                <EmptyText table>{t("noLearnersEnrolled")}</EmptyText>
            )}
            <WithdrawEnrollmentModal
                actions={withdrawEnrollmentConfirmationActionArray}
                isOpen={showWithdrawEnrollmentConfirmationModal}
                onClose={setShowWithdrawEnrollmentConfirmationModal}
                enrollmentName={enrollmentToWithdraw?.user?.getFirstAndLastNameOrEmail() ?? ""}
            />
        </>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { EnrollmentSummaryList };

// #endregion Exports
