import { Culture } from "andculturecode-javascript-core";
import { CultureCodes } from "constants/localization/culture-codes";
import { cultureFactory } from "utilities/localization/culture-factory";
import { CultureResources } from "utilities/interfaces/culture-resources";
import { UnicodeCharacterConstants } from "constants/unicode-character-constants";

// -------------------------------------------------------------------------------------------------
// #region Translations
// -------------------------------------------------------------------------------------------------

const placeholderSpanishTranslations = {
    doYouPlanToOfferTraining: "Planea ofrecer capacitación:",
    catalogPath: "LibrosREA",
    ToMakeTheChange: " para realizar el cambio.",
    contactTheAENAdministrator: "contacta a la administración de REA",
    yourExclusiveDiscountCodeIsTiedToTheAdminEmailEnteredUponRegistrationForTheNFPAAuthorizedEducationNetworkIfYouWouldLikeToUseADifferentEmailPlease:
        "*Tu código de descuento exclusivo está vinculado al correo electrónico de administración introducido al registrarte en la Red de Educación Autorizada de NFPA. Si deseas usar un correo electrónico diferente, por favor ",
    ifYouWantToProvidePrintedNFPACodesStandardsToYourStudentsYouCanPurchaseThemAtADiscountedPriceFromNFPAPleaseNoteYouAreStillResponsibleForShippinghandlingAndApplicableTaxesOnOrdersPlacedFromNFPAAndYourProviderDiscountIsOnlyForPrintPublicationsUsedForTrainingClassesBooksAreNotForResaleForOrdersPlacedOutsideTheUSPleasePlanForAnAdditionalWeeksForDeliveryAndAnyAdditionalLocalAndImportTaxes:
        "Si quieres proporcionar códigos y normas de NFPA impresos a tus alumnos, puedes comprarlos a NFPA con descuento*. Ten en cuenta que seguirás siendo responsable de los gastos de envío e impuestos aplicables a los pedidos realizados a NFPA, y que el descuento de proveedor sólo se aplica a las publicaciones impresas que se usen en las clases de capacitación. Los libros no son para reventa. Para pedidos realizados fuera de EE.UU., prevé 3 semanas para la entrega y cualquier impuesto local y de importación adicional.",
    toRunThisReportYouMustSelectEitherAnEventsOrADateRangeYouMayAlsoSelectBoth:
        "Para ejecutar este informe, debe seleccionar al menos un evento y/o un rango de fechas a continuación.",
    notApplicable: "No Aplicable",
    typeToSearchByProviderIdOrName: "Escriba para buscar por ID de proveedor o nombre",
    pleaseConfirmThatAllLearnersAreMarkedAsAttendedOrNoShow:
        "Confirme que todos los estudiantes estén marcados como Asistieron o No se presentaron.",
    noShow: "No presentarse",
    notAttended: "No Asistió",
    partiallyAttended: "Parcialmente Asistido",
    youCanNotEditContentFromATrainTheTrainerProductInOrderToEditContentYouMustEditTheAssociatedProduct:
        "No puedes editar contenido de un producto de Entrenador de Entrenadores. Para editar contenido, debes editar el producto asociado.",
    youAreViewingContentFromTheAssociatedTrainingProduct:
        "Estás viendo contenido del producto de capacitación asociado.",
    approvedToTeachProduct: "Aprobado para enseñar producto",
    instructorDirectory: "Directorio de instructores",
    youAreNowEditingACompleteEvent: "Ahora estás editando un evento completo.",
    thisEventIsComplete: "Este evento está completo.",
    unableToEdit: "No se puede editar",
    goToAenHub: "ir a Aen Hub",
    onceYouHaveCompletedAllRequiredElementsOfTheTrainingYouWillReceiveAnEmailFromCredlyInvitingYouToClaimYourDigitalBadgeTheBadgeWillAllowYouToEasilyShareYourAchievementWithColleaguesManagersAndYourProfessionalNetworkOnLinkedInAndOtherPlatforms:
        "Una vez que haya completado todos los elementos requeridos de la capacitación, recibirá un correo electrónico de Credly invitándolo a reclamar su insignia digital. La insignia le permitirá compartir fácilmente sus logros con colegas, gerentes y su red profesional en LinkedIn y otras plataformas.",
    weHaveIntegratedWithCredlyToTranslateTheLearningOutcomesYouveDemonstratedIntoABadgeIssuedAndManagedThroughTheCompanyDigitalBadgingPlatformTheTechnologyCredlyUsesIsBasedOnTheOpenBadgeStandardsMaintainedByIMSGlobalThisEnablesYouToManageShareAndVerifyYourCompetenciesDigitally:
        "Nos hemos integrado con Credly para traducir los resultados de aprendizaje que ha demostrado en una insignia, emitida y administrada a través de la plataforma de credenciales digitales de la empresa. La tecnología que utiliza Credly se basa en los estándares Open Badge mantenidos por IMS Global. Esto le permite gestionar, compartir y verificar sus competencias digitalmente.",
    changesToContentWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios en el contenido se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso de un alumno.",
    youCanOnlyMakeChangesToTheProductsContent:
        "Solo puede realizar cambios en el contenido del producto",
    changesWillNotBeAppliedToInstructorsCurrentlyAssignedToThisProductYouWillNeedToManuallyReassignThisTrainingToTheInstructor:
        "Los cambios no se aplicarán a los instructores actualmente asignados a este producto. Deberá reasignar manualmente esta capacitación al instructor.",
    allCurrentlyEnrolledInstructorsWillRemainOnTheCurrentVersionToMigrateThemToTheNewVersionManuallyReassignThisTraining:
        "Todos los instructores actualmente inscritos permanecerán en la versión actual. Para migrarlos a la nueva versión, reasigne manualmente esta capacitación.",
    applicationSubmittedDescription:
        "Su solicitud ha sido recibida. La NFPA pronto comenzará el proceso de revisión.",
    underReviewDescription:
        "Los detalles de su solicitud se encuentran actualmente bajo revisión. NFPA se comunicará con usted si se requiere más información.",
    pendingAssessmentDescription:
        "Se le han asignado las evaluaciones para los cursos solicitados en su solicitud. Debe aprobar las evaluaciones en tres intentos y recibir una puntuación mínima del 80% para pasar a la etapa de revisión final.",
    finalReviewInProgressDescription:
        "Su solicitud y el envío del video se encuentran en la etapa de revisión final. Se le notificará una vez que se tome una determinación sobre su aprobación.",
    finalReviewInProgress: "Revisión final en progreso",
    thereWasAnIssueReturningTheInstructorApplicationStatus:
        "Hubo un problema al devolver el estado de la solicitud de instructor.",
    thereWasAProblemReturningTheInstructorApplicationEnrollments:
        "Hubo un problema al devolver las inscripciones de la aplicación del instructor",
    applicationSubmitted: "Solicitud Enviada",
    myApplicationStatus: "Mi estado de solicitud",
    requiredAssessment: "Evaluación requerida",
    pendingApproval: "Pendiente de aprobación",
    instructorStatus: "Estado del instructor",
    instructorAssessment: "Evaluación del instructor",
    forInvoiceStatus: "Para el estado de la factura...",
    invoiced: "Facturado",
    invoiceStatus: "Estado de la factura",
    invoiceStatusType: "Tipo de estado de la factura",
    notInvoiced: "No facturado",
    notInvoicedAndInvoiced: "No facturado y facturado",
    aProviderAlreadyExistsWithThisNetsuiteId: "Ya existe un proveedor con este ID de Netsuite",
    enterNetsuiteId: "Introduzca el ID de NetSuite",
    netsuiteId: "ID de NetSuite",
    messageToApplicant: "Mensaje al solicitante",
    messageSentToApplicant: "Mensaje enviado al solicitante",
    sendMessageToApplicant: "Enviar mensaje al solicitante",
    emailBody: "Cuerpo del correo electrónico",
    applicationHasBeenUpdatedSuccessfully: "La aplicación se actualizó correctamente",
    thereWasAnIssueUpdatingTheApplication: "Hubo un problema al actualizar la aplicación",
    updateStatus: "Actualizar estado",
    reviewer: "Revisor",
    applicationSuccessfullyUpdated: "La aplicación se actualizó correctamente",
    applicationUpdateFailed: "La actualización de la aplicación falló",
    reviewCompleteAccepted: "Revisión completa - Aceptada",
    reviewCompleteDeclined: "Revisión completa - Rechazada",
    hasbeeninvoiced: "Se ha facturado",
    eventInvoicing: "Facturación de eventos",
    enterNotes: "Ingrese notas",
    activityBy: "Actividad por",
    addNotes: "Agregar notas",
    addNote: "Agregar nota",
    notes: "Notas",
    thereWasAnIssueReturningActivityLogs:
        "Hubo un problema al devolver los registros de actividad.",
    thereWasAnIssueSavingTheNotes: "Hubo un problema al guardar las notas.",
    noteHasBeenAddedSuccessfully: "La nota se agregó correctamente.",
    resume: "Currículum",
    thereWasAnIssueReturningApplicationFiles:
        "Hubo un problema al devolver los archivos de la aplicación.",
    thereWasAnIssueSavingTheFile: "Hubo un problema al guardar el archivo.",
    trainingSpace: "Espacio de entrenamiento",
    fileTypeSelection: "Selección de tipo de archivo",
    enterDescription: "Ingrese una descripción",
    addFile: "Agregar archivo",
    uploadDate: "Fecha de carga",
    uploadedBy: "Subido por",
    fileType: "Tipo de archivo",
    addNewFile: "Agregar nuevo archivo",
    fileUploads: "Cargas de archivos",
    linkedInProfile: "Perfil de Linkedin",
    currentNfpaMember: "¿Es usted actualmente miembro de NFPA?",
    nfpaTechnicalCommitteeMember: "¿Es usted actualmente miembro del Comité Técnico de NFPA?",
    ifYesWhichOnes: "¿Qué comités técnicos?",
    areYouCurrentlyAnApprovedNfpaInstructor:
        "¿Es usted actualmente un instructor aprobado por la NFPA?",
    haveYouPreviouslyBeenAnApprovedNfpaInstructor:
        "¿Ha sido anteriormente un instructor aprobado por la NFPA?",
    whyDoYouWantToBecomeAnNfpaInstructor: "¿Por qué quiere convertirse en instructor de NFPA?",
    areYouInterestedInDeliveringInPersonClassroomTraining:
        "¿Está interesado en impartir capacitación presencial en el aula?",
    areYouInterestedInDeliveringVirtualTraining:
        "¿Estás interesado en impartir capacitación virtual?",
    doYouHaveExperienceDeliveringTrainingBasedOnNfpaCodesAndStandards:
        "¿Tiene experiencia impartiendo capacitación basada en códigos y estándares de NFPA?",
    ifYesPleaseBrieflyDescribeYourExperienceEGTopicsAudienceClassSize:
        "En caso afirmativo, describa brevemente su experiencia (por ejemplo, temas, audiencia, tamaño de la clase)",
    doYouHaveExperienceDeliveringVirtualTraining:
        "¿Tiene experiencia impartiendo capacitación virtual?",
    ifYesPleaseBrieflyDescribeYourExperienceAndSkillComfortLevelDoingSo:
        "En caso afirmativo, describa brevemente su experiencia y nivel de habilidad/comodidad al hacerlo.",
    areYouWillingAbleToTravelToDeliverInPersonTraining:
        "¿Está dispuesto/puede viajar para impartir capacitación en persona?",
    ifYesToWhere: "Si es así, ¿a dónde?",
    whichTopicAreasAreYouInterestedInTeaching: "¿Qué áreas temáticas le interesa enseñar?",
    pleaseListYourQualifications: "Por favor indique sus calificaciones",
    educationDegrees: "Educación/Títulos",
    certificationsLicenses: "Certificaciones/Licencias",
    listRelevantPositionsPositionRoleDates: "Lista de puestos relevantes (puesto/rol + fechas)",
    otherLanguage: "Otro idioma",
    pleaseProvideContactInformationForAtLeastTwoProfessionalReferencesWithKnowledgeOfYourSubjectMatterExpertiseAndOrTeachingAbility:
        "Proporcione información de contacto de al menos dos referencias profesionales con conocimiento de su experiencia en la materia y/o capacidad docente",
    portuguese: "Portugués",
    teachingModalities: "Modalidades de Enseñanza",
    teachingExperience: "Experiencia en la enseñanza",
    interestedTopicAreas: "Áreas temáticas de interés",
    qualifications: "Calificaciones",
    languages: "Idiomas",
    references: "Referencias",
    video: "Compartir URL de instrucción",
    ifYesWhichCourses: "En caso afirmativo, ¿qué cursos?",
    applicationDetails: "Detalles de la aplicación",
    files: "Archivos",
    activityLogs: "Registros de actividad",
    partIProviderInformation: "Parte I: Información del proveedor",
    website: "Sitio web",
    providerType: "Tipo de proveedor",
    providerBusinessSegment: "Segmento de negocio de proveedor",
    whyDoYouWantToHost: "¿Por qué quieres ser anfitrión?",
    locations: "Ubicaciones",
    partIITrainingAdministration: "Parte II: Administración de la formación",
    isThereSomeoneElseBesidesTheProviderPrimaryContactWhoWillBeResponsibleForAdministratingNfpaTrainingEvents:
        "¿Hay alguien más además del contacto principal del proveedor que será responsable de administrar los eventos de capacitación de NFPA?",
    ifYesPleaseProvideFullNameAndEmail:
        "En caso afirmativo, proporcione el nombre completo y la dirección de correo electrónico.",
    partIIITrainingSpace: "Parte III: Espacio de entrenamiento",
    tableDesksAndChairs: "Mesa/escritorios y sillas",
    whiteboardAndOrFlipChartEasels: "Caballetes de pizarra y/o rotafolio",
    equipmentToProjectPresentationsOnAScreen:
        "Equipo para proyectar presentaciones en una pantalla",
    reliableInternetConnectionWiFiForAllStudentsAndFacilitator:
        "Conexión a Internet confiable/wi-fi para todos los estudiantes y facilitadores",
    dedicatedPersonAvailableForClassroomSupport:
        "Persona dedicada disponible para apoyo en el aula",
    partIVMarketing: "Parte IV: Marketing",
    asAProviderHowWillYouPromoteTrainingCourses:
        "Como proveedor, ¿cómo promocionará los cursos de formación?",
    partVCertification: "Parte V: Certificación",
    signature: "Firma",
    title: "Título",
    techReview: "Revisión técnica",
    pendingAssessment: "Evaluación pendiente",
    aenApplications: "Aplicaciones AEN",
    filterByAssignedTo: "Filtrar por Asignado a",
    assignedTo: "Asignado a",
    companyName: "Nombre de empresa",
    contactEmail: "Email de contacto",
    goToApplication: "Ir a la aplicación",
    underReview: "Bajo revisión",
    contractInProgress: "Contrato en curso",
    declined: "Rechazado",
    creditCheckInProgress: "Verificación de crédito en progreso",
    moreInfoNeeded: "Se necesita más información",
    thereWasAnIssueLoadingProviderApplications:
        "Hubo un problema al cargar las aplicaciones del proveedor.",
    thereWasAnIssueLoadingInstructorApplications:
        "Hubo un problema al cargar las aplicaciones de instructor.",
    yourTrialWillBeAvailableOnTheFirstDayOfTheEvent:
        "Su prueba estará disponible el primer día del evento.",
    whatAreTheDigitalBadgeTermsOfUse: "¿Cuáles son los términos de uso de la insignia digital?",
    continueToLink: "Continuar para LiNK",
    yourTrialHasAlreadyBeenClaimedDoYouWantToGoThereNow:
        "Su prueba ya ha sido reclamada. ¿Desea ir allí ahora?",
    yourTrialHasBeenClaimedSuccessfullyDoYouWantToGoThereNow:
        "Su prueba se ha reclamado correctamente. ¿Desea ir allí ahora?",
    itLooksLikeYouAlreadyHaveAnActiveLinkSubscriptionDoYouWantToGoThereNow:
        "Parece que ya tiene una suscripción activa a LiNK. ¿Desea ir allí ahora?",
    exploreLink: "Explorar LiNK",
    joinLink: "Únase LiNK",
    joinTodayToGetInstantAccessToOver1400NfpaCodesAndStandards:
        "Únase hoy para obtener acceso instantáneo a más de 1400 códigos y normas de NFPA",
    theresMoreToExploreWithLink: "Hay más por explorar con LiNK",
    numDaysOfFreeAccessRemaining: "{{num}} Días de acceso gratuito restantes",
    youStillHaveInstantAccessToOver1400NfpaCodesAndStandards:
        "Todavía tiene acceso instantáneo a más de 1400 códigos y normas de NFPA",
    newUsersGetInstantAccessToOver1400NfpaCodesAndStandards:
        "Los nuevos usuarios obtienen acceso instantáneo a más de 1400 códigos y normas de NFPA",
    freeAccessForNumberDays: "Acceso gratuito por {{number}} días",
    youMustManuallyDeleteTheExistingPackageBeforeReplacingIt:
        "Debe eliminar manualmente el paquete existente {{externalScormCourse}} antes de reemplazarlo.",
    searchByNameEmailOrLocation: "Buscar por nombre, correo electrónico o ubicación",
    refreshing: "Refrescante",
    refreshingInNSeconds: "Actualizándose en {{timeInSeconds}} segundos",
    accessingYourCertificateAndBadge: "Acceder a su certificado e insignia",
    launchAnInstructorLedTraining: "Lanzar una capacitación guiada por un instructor",
    launchAnOnlineLearning: "Lanzar un aprendizaje en línea",
    dashboardNavigationAndProfile: "Navegación del panel y perfil",
    accessingYourTraining: "Acceder a su capacitación",
    comingSoon: "Próximamente",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLearningPortalAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAtClientAdmin:
        "Borre la memoria caché y las cookies de su navegador de internet. Vuelva a cargar el LMS e intente acceder a su capacitación nuevamente. Si esto no funciona, comuníquese con Servicio Atentición al cliente de NFPA. Para hacerlo, seleccione el botón Enviar una pregunta.",
    changesToCoursesAreNotAvailableWhenEditingAnActiveProductVersionToChangeCourses:
        "Los cambios en los cursos no están disponibles al editar una versión activa del producto. Para cambiar cursos,",
    changesToAssessmentsAreNotAvailableWhenEditingAnActiveProductVersionToChangeAssessments:
        "Los cambios en las evaluaciones no están disponibles al editar una versión activa del producto. Para cambiar las evaluaciones,",
    approved: "Aprobado/a",
    failedToUnpublish: "Failed to unpublish",
    unpublishedSuccessfully: "Unpublished successfully",
    unpublishProduct: "Despublicar producto",
    unpublishCourse: "Despublicar curso",
    pleaseDoNotMakeChangesToTheFormatOfThisTemplateOrItWillAffectTheUploadOfTheRegistrations:
        "Por favor, no haga cambios en el formato de esta plantilla o afectará la carga de las inscripciones.",
    importingACSVFileIsAnEasyWayToBulkEnrollMultipleLearnersAtOnceToGetStarted:
        "Importar un archivo CSV es una forma sencilla de inscribir de forma masiva a varios alumnos a la vez. Para empezar, ",
    emailsMustBeUniqueToTheIndividualRegistrant:
        "Los correos electrónicos deben ser únicos para el inscrito individual.",
    orderInfo: "Información del pedido",
    invalidData: "Datos no válidos",
    productNotFound: "Producto no encontrado",
    thereWasAnIssueLoadingCatalogEnrollmentLogs:
        "Hubo un problema al cargar los registros de inscripción en el catálogo.",
    noInvalidAttendees: "No hay asistentes no válidos",
    noValidAttendees: "No hay asistentes válidos",
    errors: "Errores",
    invalidAttendees: "Asistentes válidos",
    validAttendees: "Asistentes válidos",
    errorParsingCSV: "Error al analizar el archivo CSV",
    catalogEnrollmentLog: "Registro de inscripción en el catálogo",
    saveValidAttendees: "Guardar asistentes válidos",
    past: "Pasadas",
    hideLocationInformation: "Ocultar información de ubicación",
    datePickerDateFormat: "dd/MM/yyyy",
    manageEnrollments: "Gestionar inscripciones",
    anOrganizationAlreadyExistsWithThisNetSuiteCustomerId:
        "Ya existe una organización con este ID de cliente de NetSuite.",
    copiedToClipboard: "Copiado al portapapeles",
    claimNow: "Reclamar ahora",
    freeAccessForNumDays: "Acceso gratuito por {{trialDays}} días",
    useCode: "Usar el código",
    toGetInstantAccessToLiNK: "para obtener acceso instantáneo a LiNK",
};

const professionalSpanishTranslations = {
    aenInstructorInformationPage: "página de información para instructores REA",
    forMoreInformationOnBecomingAnNFPAInstructorPleaseVisit:
        "Para obtener más información sobre cómo convertirse en Instructor de NFPA, visita la ",
    forSpecificFeedbackPleaseReferToTheEmailThatWasSentToYou:
        "Para obtener información específica, consulta el correo electrónico que te hemos enviado.",
    thankYouForTakingTheTimeToApplyToBeAnApprovedNFPAInstructorWeAppreciateYourInterestAndTheEffortYouPutIntoYourApplicationUnfortunatelyWeHaveFoundThatYourQualificationsDoNotMeetTheRequirementsAtThisTime:
        "Gracias por tomarte el tiempo de postularte para ser instructor aprobado por NFPA. Apreciamos tu interés y el esfuerzo que has dedicado a tu solicitud. Lamentablemente, hemos determinado que tus cualificaciones no cumplen los requisitos en este momento.",
    SelectAnAENProviderIfTheInstructorIsAssociatedWithAnExistingAENProviderEgTheInstructorIsAnEmployeeOfTheAENProviderOrganization:
        "Seleccione un proveedor AEN si el instructor está asociado con un proveedor AEN existente (p. ej., el instructor es un empleado de la organización proveedora AEN)",
    aGroup: "Grupo A",
    aProviderAlreadyExistsWithThisName: "Ya existe un proveedor con este nombre",
    aProviderAlreadyExistsWithThisNfpaId: "Ya existe un proveedor con esta identificación de NFPA",
    addGroup: "Agregar grupo",
    addGroups: "Agregar grupos",
    addNewContent: "Agregar nuevo contenido",
    addNewGroup: "Agregar nuevo grupo",
    addProductApproval: "Agregar aprobación de producto",
    addUsers: "Agregar usuarios",
    aenProductCatalog: "Catálogo de productos AEN",
    aenResources: "Recursos de AEN",
    allEvents: "Todos los eventos",
    approvedToTeachProductCreatedSuccessfully:
        "El producto aprobado para enseñar se creó correctamente.",
    approvedToTeachProductRemovedSuccessfully:
        "El producto aprobado para enseñar se eliminó correctamente.",
    areYouSureYouWantToRemoveContent: "¿Está seguro de que desea eliminar {{content}}?",
    areYouSureYouWantToRemoveThisUserFromThisGroup:
        "¿Está seguro de que desea eliminar a {{user}} de {{group}}?",
    areYouSureYouWantToRevokeApproval:
        "¿Está seguro de que desea revocar la aprobación de {{instructorName}} para enseñar {{productName}}?",
    associateThisUserToAnExistingENProvider: "Asociar este usuario a un proveedor AEN existente",
    associatedENProvider: "Proveedor AEN asociado",
    authorizedEducationNetworkTrainings: "Capacitaciones de la red de educación autorizada",
    availableForAEN: "Disponible para AEN",
    backToAllProducts: "Volver a todos los productos",
    beforeBuildingYourSchedule: "antes de crear su cronograma.",
    bySelectingAnAENProviderTheInstructorCanStillBeAssignedToEventsForTheAssociatedAENProviderEvenIfTheyAreExcludedFromTheAENProviderDirectoryAndSearch:
        "Al seleccionar un proveedor AEN, aún se puede asignar el instructor a eventos para el proveedor AEN asociado, incluso si está excluido del directorio de proveedores AEN y de la búsqueda",
    calendarView: "Vista de calendario",
    certificates: "Certificados",
    changesToCoursesAreNotAvailableWhenEditingTheCurrentProductVersionToChangeCourses:
        "No puede realizar cambios a los cursos al editar la versión actual del producto. Para cambiar cursos,",
    clientTrainingAdmin: "Administrador de capacitación del cliente",
    completeCoursesInOrder: "Completar cursos en orden",
    contentCreatedSuccessfully: "El contenido se creó correctamente.",
    contentDeletedSuccessfully: "El contenido se eliminó correctamente.",
    contentForInstructors: "Contenido para instructores",
    contentForProviders: "Contenido para proveedores",
    contentUpdatedSuccessfully: "El contenido se actualizó correctamente.",
    courseObjectives: "Objetivos del curso",
    courseObjectivesInclude: "Entre los objetivos del curso se encuentran",
    createSomethingNew: "Crear algo nuevo",
    creatingGroupUsersFailed: "Error al crear usuarios del grupo.",
    creatingaenresourcefailed: "creatingAenResourceFailed",
    displaysTrainingsWhereAccessHasExpiredOrTrainingRequirementsAreComplete:
        "Muestra capacitaciones donde el acceso ha caducado o los requisitos de capacitación están completos.",
    download: "Descargar",
    downloadAndPrint: "Descargar e imprimir",
    downloadButtonForResourceResourceName: "Botón de descarga para el recurso {{resourceName}}",
    editGroup: "Editar grupo",
    emailManager: "Administrador de correo electrónico",
    emailSubject: "Asunto del correo electrónico",
    enProviderAdmin: "Administrador de proveedores AEN",
    enProvidersNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "Los proveedores AEN se deben agregar antes de asignarlos a un usuario.",
    enrollmentForTrainingAlreadyExists: "Ya existe la inscripción para la capacitación.",
    enterGroupDescription: "Ingrese la descripción del grupo",
    enterGroupName: "Ingrese el nombre del grupo",
    enterLongDescription: "Ingrese la descripción larga",
    enterNetSuiteCustomerID: "Ingrese el ID de cliente de NetSuite",
    errorFetchingProducts: "Error al recuperar productos",
    eventAttendees: "Asistentes al evento",
    eventViewType: "Tipo de vista de evento",
    excludeFromAenProviderDirectoryAndSearch:
        "Excluir del directorio y de la búsqueda de proveedores AEN",
    failedToActivateProductVersion: "Error al activar la versión del producto.",
    failedtoupdateaenresource: "Error al actualizar el recurso de AEN.",
    filterByAvailableForAEN: "Filtrar por disponible para AEN",
    filterByType: "Filtrar por tipo",
    goToYourDashboard: "Vaya a su panel",
    group: "Grupo",
    groupDescription: "Descripción del grupo",
    groupHasBeenAddedSuccessfully: "El grupo se agregó correctamente",
    groupHasBeenUpdatedSuccessfully: "El grupo se actualizó correctamente",
    groupInformation: "Información del grupo",
    groupName: "Nombre del grupo",
    groups: "Grupos",
    hasAssessment: "Tiene evaluación",
    iWantToRunAnAllUserReportByThisUserRole:
        "Quiero ejecutar un informe de todos los usuarios según esta función o grupo de usuarios...",
    iWantToRunAnEnrollmentReportBasedOn: "Quiero ejecutar un informe de inscripción basado en...",
    iWantToSendAnEmailTo: "Quiero enviar un correo electrónico a,",
    ifTheEventHoursWillExceedTheDefaultCEUValueTheCEUAmountMayBeAdjustedCommonValidReasonsTheEventMayRunLongerInclude:
        "Cada hora de clase equivale a 0,1 CEU. Si las CEU del evento superan el valor predeterminado de CEU, se puede ajustar la cantidad de CEU. Algunos motivos válidos comunes por los que el evento puede durar más son los siguientes:",
    instructorRoleWasAddedButSavingInstructorInformationFailed:
        "Se agregó la función de instructor, pero no se pudo guardar la información del instructor.",
    intendedAudience: "Público previsto",
    lastLoginDate: "Última fecha de inicio de sesión",
    linkTrialId: "ID de prueba",
    linkTrialInformationWithHTML: `NFPA LiNK<sup>${UnicodeCharacterConstants.Registered}</sup> Información de prueba`,
    linkTrialToken: "Token de prueba",
    listView: "Vista de lista",
    longDescription: "Descripción larga",
    nameOfGroup: "Nombre del grupo",
    nameOfOrganization: "Nombre de la organización",
    netsuiteCustomerID: "ID de cliente de NetSuite",
    newGroup: "Nuevo grupo",
    nfpaWebId: "ID web de NFPA",
    noAssessmentsAdded: "No se agregaron evaluaciones",
    noCertificateResultsFound: "No se encontraron resultados de certificados",
    noContentResultsFound: "No se encontraron resultados de contenidos",
    noResourcesFound: "No se encontraron recursos",
    notAvailableForAen: "No disponible para Aen",
    optionalAenProviderAssociationForInstructor:
        " “Asociación de proveedores AEN opcionales para el instructor”",
    pleaseAdjustYourScheduleOrContactNFPAForApproval:
        "Ajuste su CEU o comuníquese con NFPA para obtener aprobación.",
    problemLoadingProvider: "Problema al cargar proveedor",
    productCatalog: "Catálogo de productos",
    productImage: "Imagen del producto",
    productVersionIsNowActive: "La versión del producto ya está activa.",
    providerHasBeenAddedSuccessfully: "El proveedor se agregó correctamente",
    providerHasBeenUpdatedSuccessfully: "El proveedor se actualizó correctamente",
    providers: "Proveedores",
    recipientType: "Tipo de destinatario",
    removeUser: "Eliminar usuario",
    requirePassed: "Exigir aprobación",
    revokeApproval: "Revocar aprobación",
    revoked: "Se revocó",
    searchByCourseNameOrID: "Buscar por nombre o ID de curso",
    searchByProductNameOrID: "Buscar por nombre o ID de producto",
    selectAGroup: "Seleccione un grupo",
    selectATemplate: "Seleccione una plantilla",
    selectAnEvent: "Seleccione un evento",
    selectGroups: "Seleccione grupos",
    sendAnEmail: "Envíe un correo electrónico",
    sendEmail: "Enviar correo electrónico",
    includeAenNetwork: "Incluir red de educación autorizada",
    tCreditHourEqualstCEUs: "{{creditHour}} Hora de crédito = {{ceu}} CEU",
    theRecomendedCEUsForSelectedProductIs:
        "Las CEU que se recomiendan para el producto seleccionado son {{productCEUs}}.",
    theRecommendedCEUsForThisEventIsProductCEUsHoursAndShouldNotExceedMaxCEUs:
        "Las CEU que se recomiendan para este evento son {{productCEUs}} CEU y no deben exceder {{maxCEUs}} CEU.",
    theStatusAsAnActiveAenProviderIsContingentUponTheOrganizationsComplianceWithTheAenContractTimelyPaymentsAndFavorableCustomerEvaluations:
        "El estatus como proveedor AEN activo depende del cumplimiento por parte de la organización del contrato con AEN, los pagos oportunos y las evaluaciones favorables de los clientes.",
    thereWasAProblemSendingTheEmail: "Hubo un problema al enviar el correo electrónico.",
    thereWasAProblemSyncingTheUser: "Hubo un problema al sincronizar el usuario.",
    thereWasAnErrorCreatingTheContent: "Hubo un error al crear el contenido.",
    thereWasAnErrorSavingTheContent: "Hubo un error al guardar el contenido.",
    thereWasAnIssueCreatingTheApprovedToTeachProduct:
        "Hubo un problema al crear el producto aprobado para enseñar.",
    thereWasAnIssueCreatingTheGroup: "Hubo un problema al crear el grupo",
    thereWasAnIssueCreatingTheProvider: "Hubo un problema al crear el proveedor",
    thereWasAnIssueDeletingTheContent: "Hubo un problema al eliminar el contenido",
    thereWasAnIssueLoadingAenResources: "Hubo un problema al cargar los recursos de AEN",
    thereWasAnIssueLoadingGroupUsers: "Hubo un problema al cargar los usuarios del grupo",
    thereWasAnIssueLoadingGroups: "Hubo un problema al cargar los grupos.",
    thereWasAnIssueLoadingTheGroup: "Hubo un problema al cargar el grupo.",
    thereWasAnIssueLoadingTheInstructorsApprovedToTeachProducts:
        "Hubo un problema al cargar los productos aprobados para enseñar del instructor.",
    thereWasAnIssueLoadingUsers: "Hubo un problema al cargar los usuarios.",
    thereWasAnIssueRemovingTheApprovedToTeachProduct:
        "Hubo un problema al eliminar el producto aprobado para enseñar.",
    thereWasAnIssueRemovingTheUser: "Hubo un problema al eliminar el usuario.",
    thereWasAnIssueSavingTheGroup: "Hubo un problema al guardar el grupo.",
    thereWasAnIssueUpdatingTheGroup: "Hubo un problema al actualizar el grupo",
    thereWasAnIssueUpdatingTheProvider: "Hubo un problema al actualizar el proveedor",
    therewasanissuesavingthecontent: "Hubo un problema al guardar el contenido.",
    therewasaproblemcreatinganewenrollment: "Hubo un problema al crear una nueva inscripción.",
    theseEventsAreForTheAENOnlyAndRunByTheAENProvider:
        "Estos eventos son solo para AEN y los ejecuta el proveedor AEN",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizerWhenUseDefaultDuringTheEventCreationProcess:
        'Esta información de contacto estará disponible para los participantes en el evento como método principal para comunicarse con el organizador del evento cuando se seleccione "Usar valor predeterminado" durante el proceso de creación del evento.',
    tileView: "Vista en mosaico",
    toCreateAnEventForAnotherProductGoToEventsAndSelectNewEvent:
        "Para crear un evento para otro producto, vaya a Eventos y seleccione 'Nuevo evento'.",
    trainingViewType: "Tipo de vista de capacitación",
    typeToSearchByInstructorName: "Escriba para buscar por nombre de instructor",
    typeToSearchByNameOrEmail: "Escriba para buscar por nombre o correo electrónico",
    updateAenResourceFailed: "Error al actualizar el recurso de AEN.",
    updateaenresourcesuccessful: "Actualización correcta del recurso de AEN.",
    uploadAProductImage: "Cargue una imagen del producto",
    useThisToggleToSettoAllDAyWhenSessionTimesAreUnknown:
        "Use esta opción para configurar todo el día cuando se desconozcan los horarios de las sesiones.",
    user: "Usuario",
    userId: "ID de usuario",
    userName: "Nombre de usuario",
    userRemovedSuccessfully: "El usuario se eliminó correctamente.",
    userWasUndefined: "El usuario no estaba definido",
    viewGroup: "Ver grupo",
    whatDoYouWantToSay: "¿Qué desea decir?",
    whoWillBenefit: "¿Quién se beneficiará?",
    youAreCreatingAnEventForPropsProductName: "Está creando un evento para {{propsProductName}}",
    youAreCurrentlyNotApprovedToTeachAnyTrainings:
        "Actualmente no está aprobado/a para impartir ninguna capacitación.",
    youHaveNoRequiredTrainings: "No tiene capacitaciones requeridas.",
    yourContactInformationWillComeDirectlyFromYourNFPAProfileYourContactInformationWillBeAvailableToLMSAdministratorsForSchedulingPurposesItWillAlsoBeVisibleToEnrolledLearnersForEventsInWhichYouAreTheInstructor:
        "Su información de contacto vendrá directamente de su perfil de NFPA. Su información de contacto estará disponible para los administradores de LMS para fines de programación. También será visible para los alumnos inscritos en eventos en los que usted sea el instructor.",
    yourEmailHasBeenSent: "Se envió el correo electrónico.",
    yourLocationInformationWillComeDirectlyFromYourNFPAProfileIfYouWouldLikeYourLocationInformationVisibleToAENProvidersPleaseMoveTheToggleToTheRightToTurnOnTheShareMyLocationInformationFeature:
        'Su información de ubicación vendrá directamente de su perfil de NFPA. Si desea que la información de su ubicación sea visible para los proveedores AEN, deslice el botón de cambio hacia la derecha para activar la función "Compartir información de mi ubicación".',
    yourOrganizationHasNoPastOnlineTrainings:
        "Su organización no cuenta con capacitaciones en línea pasadas",
    yourReportIsReady: "Está listo su informe",
    youveBeenGrantedAnExceptionToExceedMaxCEUsHoursForYourEvent:
        "Se le concedió una excepción para superar {{maxCEUs}} CEU para su evento.",
    instructorsFAQs: "Preguntas frecuentes de los instructores",
    AENProviderFAQ: "Preguntas frecuentes de los proveedores AEN",
    allCourseMaterialsWillBeAvailableDigitallyToParticipantsAndTheInstructorOnTheLMS:
        "Todos los materiales del curso estarán disponibles digitalmente para los participantes y el instructor en el LMS.",
    aPastTrainingIsATrainingEventWhereAllOfTheEventDatesareInThePast:
        "Un entrenamiento pasado es aquel para el cual ya pasaron todas las fechas de la actividad.",
    areYouSureYouWouldLikeToLeaveWithoutCreatingANewVersion:
        "¿Seguro desea salir sin crear una nueva versión?",
    badgeFAQs: "Preguntas frecuentes sobre insignias",
    buildingLifeSafety: "Seguridad humana y de edificaciones",
    byDeactivatingThisCourseItWillNoLongerBeAvailableToAddToAProductTheCourseWillStillAppearInTheListViewOfCourses:
        "Una vez que desactive este curso, ya no estará disponible para agregarlo a un producto. De todas formas, seguirá apareciendo en la vista de la lista de cursos.",
    canIaddmoreparticipantstotheclassroster:
        "¿Puedo agregar más participantes a la lista de la clase?",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentProductVersionToChangeAssessmentsCreateANewProductVersion:
        "No puede realizar cambios en las evaluaciones al editar la versión actual del producto. A fin de llevar dicho cometido, cree una nueva versión del producto.",
    changesToBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios de insignia y certificados se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    changesToCEUsWillBeAppliedToTheRecommendedClassTimeForAnEventAndWillNotImpactOrResetTheEventScheduleForPublishedOrDraftEvents:
        "Los cambios realizados a las CEU se aplicarán al tiempo de clase sugerido para un evento y no afectarán ni restablecerán el Calendario de eventos ya sean publicados o borradores.",
    changesToContentBadgesAndCertificatesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios de contenido, insignia y certificados se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    changesToTheEvaluationAreNotAvailableFollowingInitialCreationOfTheProduct:
        "No puede realizar cambios en la evaluación después de la creación inicial del producto.",
    clearYourInternetBrowsersCacheAndCookiesReloadTheLMSAndTryToAccessYourTrainingAgainIfThisDoesNotWorkPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "Borre la memoria caché y las cookies de su navegador de internet. Vuelva a cargar el LMS e intente acceder a su capacitación nuevamente. Si esto no funciona, comuníquese con Atención al cliente de NFPA. Para hacerlo, seleccione el botón Enviar una pregunta.",
    clickContinueToCreateANewVersionThisActionWillReturnYouToTheProductVersionListWhereYouCanActivateAndDetermineHowToApplyThisNewVersionToInProgressLearners:
        "Haga clic en Continuar para crear una nueva versión. Esta acción lo regresará a la lista de versiones del producto donde podrá activar y determinar cómo aplicar esta nueva versión a los estudiantes en curso.",
    clientAdminFAQ: "Preguntas frecuentes sobre administradores de clientes",
    completeAssessmentsInOrder: "Completar evaluaciones en orden",
    courseseries: "Curso o serie",
    deactivatingThisProductWillBeReflectedONLYWithinTheLMSBeSureToUpdateAllAssociatedSystemsIncludingTheNFPACatalogOnceDeactivatedThisProductWillNoLongerBeAvailableForNewEnrollmentsOrContracts:
        "La desactivación de este producto se reflejará ÚNICAMENTE dentro del LMS. Asegúrese de actualizar todos los sistemas asociados, incluido el Catálogo de la NFPA. Una vez desactivado, este producto ya no estará disponible para nuevas inscripciones o contratos.",
    emergingTechnology: "Tecnología emergente",
    erpId: "Identificación de la ERP",
    fireProtection: "Sistemas de protección contra incendio",
    howChangesAreApplied: "Cómo se aplican los cambios",
    ifTheCertificateIsSpecificallyForHotWorkSelectYes:
        "Si el certificado es específicamente para trabajo en caliente, seleccione “Sí”.",
    industrialChem: "Peligros industriales",
    learnerFAQs: "Preguntas frecuentes de estudiantes",
    LocateTheEventFromEitherTheEventCalendarOrTheEventsQuickLinkFromYourDashboardIfLocatingFromTheEventCalendarSelectTheSpecificEventAndThenSelectTheEditEventButtonThatWillAppearOnTheRighthandSideOfTheCalendarIfLocatingFromTheEventsQuickLinkUseTheEllipsisAndChooseEditEventNavigateThroughTheEventToEditAsNecessaryOrSelectTheCancelEventButtonAtTheBottomOfTheLefthandSidePaneAllLearnersAndInstructorsWillAutomaticallyReceiveBannerUpdatesConcerningTheChangesOrCancellation:
        "Localice el evento desde el Calendario de eventos o el enlace rápido Eventos desde su panel de control. Si lo busca desde el calendario de eventos, seleccione el evento específico y luego seleccione el botón Editar evento que aparecerá en el lado derecho del calendario. Si lo busca desde el enlace rápido de eventos, use los puntos suspensivos y elija Editar evento. Navegue por el evento para editarlo según sea necesario o seleccione el botón Cancelar evento en la parte inferior del panel lateral izquierdo. Todos los alumnos e instructores recibirán automáticamente actualizaciones en el área de notificaciones emergentes sobre cambios o cancelaciones.",
    newAndDraftEventsWillReceiveThisNewVersion:
        "Los eventos nuevos y los borradores de eventos recibirán esta nueva versión.",
    newlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "Los eventos recién publicados recibirán la última versión activa del producto ILT.",
    NoAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactYourSalesRepresentative:
        "No, todos los materiales del curso han sido revisados por el personal técnico de NFPA.",
    noContentAdded: "No se agregó contenido.",
    notAtThisTime: "No en este momento.",
    noThisIsAServiceWeProvideToYouAtNoCost:
        "No. Este es un servicio que le brindamos sin costo alguno.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersion:
        "Una vez que se publica un evento, se vincula a una versión del producto ILT y permanecerá en esa versión.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatus:
        "Una vez que se publica un evento, se vincula a una versión del producto ILT y permanecerá en esa versión independientemente del cronograma o del estado de finalización.",
    onceAnEventIsPublishedItIsTiedToAnILTProductVersionAndWillRemainOnThatVersionRegardlessOfScheduleOrCompletionStatusNewlyPublishedEventsWillReceiveTheLatestActiveILTProductVersion:
        "Una vez que se publica un evento, se vincula a una versión del producto ILT y permanecerá en esa versión independientemente del cronograma o del estado de finalización. Los eventos recién publicados recibirán la última versión activa del producto ILT.",
    ourPrimaryContactIsYourNFPASalesRepresentative:
        "Su contacto principal es su representante de ventas de NFPA.",
    PleaseContactYourNFPASalesRepresentative: "Comuníquese con su representante de ventas de NFPA.",
    pleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "",

    pleaseContactTheProviderOfTheEventForTechnologyIssuesDuringClass:
        "Comuníquese con el proveedor del evento en caso de que surjan problemas tecnológicos durante la clase.",
    PleaseWorkWithYourNFPASalesRepresentativeToProvideTheDetailsOfAnyTrainingYouWouldLikeScheduled:
        "Trabaje con su representante de ventas de NFPA para proporcionar los detalles de cualquier capacitación que desee programar.",
    publicEducation: "Educación pública",
    selectNoIfTheTrainingDoesNotHaveAnExpirationDateANoWillDisplayAnNAInTheCertificateExpirationDateField:
        "Seleccione “No” si el entrenamiento no tiene fecha de vencimiento. La opción “No” hará que aparezca un “N/A” en el campo de fecha de vencimiento del certificado.",
    selectYesIfTheTrainingHasAnExpirationDateAYesWillDisplayACertificateExpirationDateCalculatedBasedOnTheTrainingCompletionDateAndTheNumberOfYearsInputInTheHowLongIsTheCertificateValidField:
        "Seleccione “Sí” si el entrenamiento tiene fecha de vencimiento. La opción “Sí” mostrará una fecha de vencimiento del certificado calculada en función de la fecha de finalización del entrenamiento y la cantidad de años ingresados en el campo “Durante cuánto tiempo tiene validez el certificado”.",
    takeAMomentToReviewYourNewVersionAndPopulateTheChangeLogBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Tómese un momento para revisar la nueva versión y completar el registro de cambios antes de activarla. Si desea realizar algún cambio, regrese a la página correspondiente del recorrido.",
    theSelectedILTProductIsNoLongerAvailablePleaseSelectANewILTProductToPublishYourEvent:
        "El producto ILT seleccionado ya no está disponible. Seleccione un nuevo producto ILT para publicar su evento.",
    thisProductHasNoContent: "Este producto no tiene contenido.",
    wildfire: "Incendios forestales",
    YesFromTheCurrentTrainingsOnTheDashboardSelectEitherTheOnlineOrInstructorLedTabSelectViewTrainingFromTheAppropriateCourseNavigateToTheEnrollmentSectionFromTheLeftSidePaneSelectEitherTheAddNewButtonOrTheImportCSVButtonToAddParticipantsToTheRoster:
        "Sí. En la opción Capacitaciones actuales del panel, seleccione la pestaña En línea o Guiado por un instructor. Seleccione Ver capacitación en el curso apropiado. Navegue a la sección Inscripción desde el panel lateral izquierdo. Seleccione el botón Agregar nuevo o el botón Importar CSV para agregar participantes a la lista.",
    yesHoweverWeCannotGuaranteeTheInstructorsAvailability:
        "Sí, sin embargo, no podemos garantizar la disponibilidad del instructor.",
    yesPleaseWorkWithYourNFPASalesRepresentativeToOrderPhysicalMaterialsForTheTraining:
        "Sí, trabaje con su representante de ventas de NFPA para solicitar materiales físicos para la capacitación.",
    YesSummaryResultsOfTheCourseEvaluationsForInstructorledTrainingAreProvidedRealTimeSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheEvaluationSectionCanBeAccessedUsingTheLefthandPane:
        "Sí, los resultados resumidos de las evaluaciones del curso para la capacitación guiada por un instructor se proporcionan en tiempo real. Seleccione Ver capacitación desde Capacitaciones actuales y futuras o desde el ícono de puntos suspensivos debajo de Capacitaciones pasadas. Una vez que vea la capacitación deseada, puede acceder a la sección Evaluación mediante el panel que se encuentra a la izquierda.",
    YesYouCanViewOverallAssessmentDataForAClassHoweverYouCannotSeeIndividualLearnerResultsSelectViewTrainingFromEitherCurrentUpcomingTrainingsOrFromTheEllipsisIconUnderPastTrainingsOnceYouAreViewingTheDesiredTrainingTheAssessmentSectionCanBeAccessedUsingTheLefthandPane:
        "Sí, puede ver los datos de examen generales de una clase. Sin embargo, no puede ver los resultados individuales de los alumnos. Seleccione Ver capacitación desde Capacitaciones actuales y futuras o desde el ícono de puntos suspensivos debajo de Capacitaciones pasadas. Una vez que vea la capacitación deseada, puede acceder a la sección Examen mediante el panel que se encuentra a la izquierda.",
    youAreAboutToArchiveThisCourseArchivingTheCourseWillRemoveItFromTheCourseListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOnInOrderToArchiveThisCourseYouNeedTo:
        "Está a punto de archivar este curso. Si archiva el curso, lo eliminará de la vista de la lista de cursos a menos que el botón “Incluir archivados” esté activado. A fin de archivar este curso, tiene que hacer lo siguiente:",
    youAreAboutToArchiveThisProductArchivingTheProductWillRemoveItFromTheProductListViewUnlessTheIncludeArchivedToggleButtonIsTurnedOn:
        "Está a punto de archivar este producto. Si archiva el producto, lo eliminará de la vista de la lista de productos a menos que el botón “Incluir archivados” esté activado.",
    youCanConfigureYourPrivacySettingsInCredlyYoureInCompleteControlOfTheInformationAboutYourselfThatIsMadePublic:
        "Puede configurar sus ajustes de privacidad en Credly. Tiene el control total de la información personal que se hace pública.",
    youCanContinueToAccessAndUseThoseBadgesThrough:
        "Puede continuar accediendo y usando esas insignias a través del sitio web de",
    YourFirstPointOfContactWillBeNFPACustomerServicePleaseSelectTheSubmitAQuestionButtonOnThisScreenOrCallCustomerServiceDirectlyAt:
        "Su primer punto de contacto será Servicio Atención al cliente de NFPA. Seleccione el botón Enviar una pregunta que se encuentra en esta pantalla o llame directamente al:",
    youWillReceiveAnEmailNotificationFromCredlyAdmincredlycomWithInstructionsForClaimingYourBadgeAndSettingUpYourAccount:
        "Recibirá una notificación por correo electrónico de Credly (admin@credly.com) con instrucciones para reclamar su insignia y configurar su cuenta.",
    activateNewVersion: "Activar la nueva versión",
    activatingProductVersionFailed: "Error al activar la versión del producto.",
    addALearner: "Agregar a un alumno",
    AllInstructorledClassesProvideAccessToDigitalMaterialsTheHostOfTheEventWillDetermineIfPhysicalMaterialsWillAlsoBeProvided:
        "Todas las clases guiadas por un instructor proporcionan acceso a materiales digitales. El organizador del evento determinará si también se proporcionarán materiales físicos.",
    anyUpcomingEventsUsingThePreviousVersionWillReceiveThisNewVersion:
        "Cualquier evento próximo que utilice la versión anterior recibirá esta nueva versión.",
    browseNfpaTrainingCatalog:
        "Explore el Catálogo de capacitaciones de NFPA para encontrar opciones de capacitacione a fin de aumentar su conocimiento, ganar confianza en el trabajo y mejorar su demanda profesional.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "Al crear esta nueva versión, los alumnos que hayan completado la versión anterior permanecerán en esa versión. Todos los alumnos que se hayan inscrito en la versión anterior, pero que no hayan comenzado, recibirán esta nueva versión. Podrá elegir cómo aplicar esta versión a los alumnos que están con la versión actual.",
    canIChangeOrUpdateTheCourseMaterials: "¿Puedo cambiar o actualizar los materiales del curso?",
    canIExportBadgesIssuedThroughCredlyToOtherBadgestoringPlatforms:
        "¿Puedo exportar insignias emitidas a través de Credly a otras plataformas de almacenamiento de credenciales?",
    canIImportBadgesIssuedFromOtherPlatformsIntoCredly:
        "¿Puedo importar insignias emitidas desde otras plataformas a Credly?",
    canIRequestAParticularInstructor: "¿Puedo solicitar un instructor en particular?",
    canIRequestPhysicalMaterialsForAClass: "¿Puedo solicitar materiales físicos para una clase?",
    canISeeHowParticipantsScoredOnTheAssessments:
        "¿Puedo ver la puntuación que obtuvieron los participantes en los exámenes?",
    canIViewTheCourseEvaluations: "¿Puedo ver las evaluaciones del curso?",
    canMyOrganizationOrTheApprovedInstructorChangeOrUpdateTheCourseMaterials:
        "¿Puede mi organización o el instructor aprobado cambiar o actualizar los materiales del curso?",
    changesToAssessmentsAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeAssessments:
        "No puede realizar cambios en las evaluaciones al editar la versión actual del curso. Para cambiar las evaluaciones,",
    changesToAssessmentsAreTheTriggerForNewVersions:
        "Los cambios en las evaluaciones son el desencadenante de nuevas versiones.",
    changesToAssessmentsMustBeMadeInOrderToUnlockTheOtherSections:
        "Se deben realizar cambios en las evaluaciones para desbloquear las otras secciones.",
    changesToAssessmentsOrEvaluationAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheseItemsYouMustCreateANewCourseVersion:
        "No puede realizar cambios en la evaluación o evaluaciones al editar la versión actual del curso. Para cambiar estos elementos, debe crear una nueva versión del curso.",
    changesToCoursesAreTheTriggerForNewVersions:
        "Los cambios en los cursos son el desencadenante de nuevas versiones.",
    changesToCoursesMustBeMadeInOrderToUnlockTheOtherSections:
        "Se deben realizar cambios en los cursos para desbloquear las otras secciones.",
    changesToEvaluationAreUnavailableFollowingInitialCreationOfTheProduct:
        "No puede realizar cambios en la evaluación después de la creación inicial del producto.",
    changesToRecommendedCEUsWillOnlyBeAppliedToNewAndUnpublishedEventsAsFinalEventCEUsAreDeterminedByTheIndividualEventsSchedule:
        "Los cambios en las CEU recomendadas solo se aplicarán a eventos nuevos y no publicados, ya que las CEU del evento final están determinadas por el calendario de cada evento individual.",
    changesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    CompletedTrainingCanBeViewedOnTheMyLearningDashboardForTrainingWhereAccessHasNotExpiredViewTheAssignmentsSectionOnEitherTheOnlineOrInstructorledTabsAndSelectViewTrainingForTrainingWhereAccessHasExpiredViewThePastTrainingsListAtTheBottomOfTheAssignmentsPageAndSelectViewSummary:
        "Las capacitaciones completadas se pueden ver en el panel Mi aprendizaje. Para capacitaciones cuyo acceso no ha vencido, vea la sección Asignaciones en las pestañas En línea o Guiada por un instructor y seleccione Ver capacitación. Para capacitaciones cuyo acceso haya vencido, vea la lista Capacacitaciones pasadas en la parte inferior de la página Asignaciones y seleccione Ver resumen.",
    contentBadgeAndCertificateExpirationChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios de contenido, insignia y vencimiento del certificado se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    contentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios de contenido se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    createANewProductVersion: "Crear una nueva versión del producto",
    credlyIsTheEndtoendSolutionForIssuingAndManagingDigitalCredentialsCredlyWorksWithCredibleOrganizationsToProvideDigitalCredentialsToIndividualsWorldwide:
        "Credly es la solución integral para emitir y administrar credenciales digitales. Credly trabaja con organizaciones de confianza para proporcionar credenciales digitales a personas en todo el mundo.",
    CurrentlyYouMayChangeTheDefaultLanguageOfMyLearningFromEnglishToSpanishOrArabicOnlyYouCanDoThisInTheMyProfilePage:
        "Actualmente, puede cambiar el idioma predeterminado de Mi aprendizaje de inglés a español o árabe únicamente. Puede hacerlo en la página Mi perfil.",
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheCourseAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "Cada curso contiene una evaluación del curso en la que responderá preguntas específicas sobre el curso y tendrá la oportunidad de brindar comentarios abiertos.",
    EachTrainingContainsACourseEvaluationWhereYouWillAnswerSpecificQuestionsAboutTheInstructorAndEventEnvironmentAsWellAsHaveAnOpportunityToProvideOpenendedFeedback:
        "Cada capacitación contiene una evaluación del curso en la que responderá preguntas específicas sobre el instructor y el entorno del evento, y tendrá la oportunidad de brindar comentarios abiertos.",
    editProductDetails: "Editar detalles del producto",
    editVersionProductVersionVersionId: "Editar versión {{productVersionVersionId}}",
    eventsThatHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "Los eventos que hayan completado la versión anterior permanecerán en esa versión.",
    expirationDuration: "Duración del vencimiento",
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheAboutSectionOfTheEventYouWillFindTheContactInformationForTheEventHost:
        "Desde sus asignaciones de enseñanza, seleccione el botón Ver capacitación. En la sección Acerca de del evento, encontrará la información de contacto del organizador del evento.",
    fromYourTeachingAssignmentsSelectTheViewTrainingButtonOnTheEvaluationSectionOfTheEventYouWillFindASummaryOfTheEvaluationResultsParticipantsAreAskedToProvideFeedbackAboutTheInstructorOnTheFirstSixQuestionsTheSixthQuestionIsHowLikelyWouldYouBeToRecommendThisInstructorThisIsIndicativeOfYourOverallInstructorRating:
        "Desde sus asignaciones de enseñanza, seleccione el botón Ver capacitación. En la sección Evaluación del evento encontrará un resumen de los resultados de la evaluación. Se pide a los participantes que proporcionen comentarios sobre el instructor en las primeras seis preguntas. La sexta pregunta es: ¿Qué tan probable es que recomiende a este instructor? Esto es indicativo de su calificación general como instructor.",
    howCanIChangeTheDefaultLanguage: "¿Cómo puedo cambiar el idioma predeterminado?",
    howCanIOrderPhysicalMaterialsForAnUpcomingClass:
        "¿Cómo puedo pedir materiales físicos para una próxima clase?",
    howCanIUpdateMyProfileInformation: "¿Cómo puedo actualizar la información de mi perfil?",
    howCanIViewMyCompletedTraining: "¿Cómo puedo ver mi capacitación completada?",
    howCanIViewprintMyCertificatesbadges: "¿Cómo puedo ver/imprimir mis certificados/insignias?",
    howDoesMyCertificationGetDisplayedAsABadge: "¿Cómo se muestra mi certificación como insignia?",
    howDoIGetAClassScheduled: "¿Cómo puedo programar una clase?",
    howDoIJoinALiveVirtualTrainingSession:
        "¿Cómo me puedo unir a una sesión de capacitación virtual en vivo?",
    howWillIKnowIfIveEarnedABadge: "¿Cómo sabré si obtuve una insignia?",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressForTheCoursesThatHaveBeenEdited:
        "¿Cómo le gustaría aplicar esta nueva versión a los Alumnos en curso? El progreso en cualquier curso en la nueva versión no se restablecerá.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressEventsOnlyItemsThatHaveBeenChangedWillBeEffected:
        "Si desea aplicar esta nueva versión a los eventos en curso, solo se verán afectados los elementos que hayan sido modificados.",
    ifYouWouldLikeToApplyThisNewVersionToInprogressLearnersOnlyItemsThatHaveBeenChangedWillBeEffected:
        "Si desea aplicar esta nueva versión a los estudiantes en curso, solo se verán afectados los elementos que hayan sido modificados.",
    iHaveAQuestionAboutCredlyWhereCanIFindSupport:
        "Tengo una pregunta sobre Credly. ¿Dónde puedo obtener ayuda?",
    isThereAFeeToUseCredly: "¿Hay algún cargo por usar Credly?",
    laborMarketInsightsArePulledFromLiveJobRequisitionsBasedOnYourSkillsYouCanLearnWhichEmployersAreHiringWhatJobTitlesYouMightBeQualifiedForSalaryRangesAndMoreSearchActiveJobListingsAndEvenApplyForThemWithJustAFewClicksThroughCredlyAccessTheLaborMarketInsightsFromYourBadgeDetailsPageByClickingOnRelatedJobsOrByClickingOnTheSkillTagsAssignedToYourBadge:
        "La información sobre el mercado laboral se obtiene a partir de solicitudes de empleo reales. Según sus habilidades, puede saber qué empleadores están contratando, para qué puestos de trabajo podría estar calificado, rangos salariales y más. Busque ofertas de trabajo activas e incluso solicítelas con solo unos clics a través de Credly. Para acceder a información sobre el mercado laboral, vaya a la página de detalles de su insignia y haga clic en Trabajos relacionados o en las etiquetas de habilidades asignadas a su insignia.",
    learners: "Alumnos",
    liveEvents: "Eventos en vivo",
    liveEventsRegardlessOfProgressAndAllPastCompletedEventsWillContinueToUseThisProduct:
        "Los Eventos en vivo, independientemente del progreso, y todos los Eventos pasados/finalizados seguirán utilizando este Producto.",
    liveOrPastCompletedEvents: "Eventos en vivo o pasados/finalizados",
    LocateYourCompletedTrainingSeeFAQHowCanIViewMyCompletedTrainingUponViewingYourCompletedTrainingNavigateToTheAchievementsSectionFromTheLefthandPaneSelectTheButtonToDownloadCertificateAndorGoToCredly:
        "Encuentre su capacitación completada. Consulte las preguntas frecuentes: ¿Cómo puedo ver mi capacitación completada? Para ver su capacitación completada, navegue hasta la sección Logros en el panel izquierdo. Seleccione el botón para descargar el certificado o vaya a Credly.",
    needFurtherAssistanceWithTheLMSOrAccessingYourTraining:
        "¿Necesita más ayuda con el LMS o para acceder a su capacitación?",
    newEvents: "Nuevos eventos",
    noAllCourseMaterialsHaveBeenReviewedByNFPATechnicalStaffIfYouHaveFeedbackRegardingTheCourseMaterialsPleaseContactNFPACustomerServiceBySelectingTheSubmitAQuestionButtonOnThisScreenOrCallingCustomerServiceDirectlyAt:
        "No, todos los materiales del curso han sido revisados por el personal técnico de NFPA.",
    noContractResultsFound: "No se encontraron resultados de contratos",
    noCourseResultsFound: "No se encontraron resultados de cursos",
    noEventResultsFound: "No se encontraron resultados de eventos",
    noHistoryAvailable: "No hay historial disponible",
    noInstructorResultsFound: "No se encontraron resultados de instructores",
    noLearnersEnrolled: "No hay alumnos inscritos",
    noProductResultsFound: "No se encontraron resultados de productos",
    noTrainingResultsFound: "No se encontraron resultados de entrenamientos.",
    openBadgesAreWebenabledVersionsOfACredentialCertificationOrLearningOutcomeThatCanBeVerifiedInRealTimeOnline:
        "Las insignias abiertas son versiones web de una credencial, certificación o resultado de aprendizaje que se pueden verificar en tiempo real, en línea.",
    pastOnlineLearningTrainings: "Entrenamientos de aprendizaje en línea pasados",
    pleaseContactNFPAYouCanSelectTheGoToSupportButtonOnTheAboutPageOfTheOnlineTrainingOnceOnTheSupportPagePleaseSelectTheButtonToSubmitYourRequestToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHoursYouCanAlsoCallCustomerServiceDirectlyAt:
        "Comuníquese con NFPA. Puede seleccionar el botón Ir a Soporte en la página Acerca de de la capacitación en línea. Una vez en la página de Soporte, seleccione el botón para enviar su solicitud a NFPA. El equipo de atención al cliente de NFPA se comunicará con usted dentro de las 24 horas hábiles.",
    PleaseContactTheHostForTheEventTheHostContactInformationIsLocatedOnTheAboutPageOfTheEventUnderTheContactSection:
        "Comuníquese con el organizador del evento. La información de contacto del organizador se encuentra en la página Acerca de del evento en la sección Contacto.",
    pleaseContactTheProviderOfTheEventToCancelOrReschedule:
        "Comuníquese con el proveedor del evento para cancelar o reprogramar.",
    pleaseEnsureInstructorAvailabilityPriorToSettingUpATrainingTheLMSWillNotLetYouPublishAnEventUntilYouVerifyThatTheInstructorAvailabilityHasBeenConfirmed:
        "Asegúrese de la disponibilidad del instructor antes de programar una capacitación. El LMS no le permitirá publicar un evento hasta que verifique que se ha confirmado la disponibilidad del instructor.",
    pleaseReferenceYourContractWithTheInstructorAndWorkDirectlyWithThemToRectifyTheIssue:
        "Consulte su contrato con el instructor y trabaje directamente con él/ella para corregir el problema.",
    pleaseSelectTheButtonToSubmitYourQuestionToNFPATheNFPACustomerSupportTeamWillGetBackToYouWithinBusinessHours:
        "Seleccione el botón para enviar su pregunta a NFPA. El equipo de atención al cliente de NFPA se comunicará con usted dentro de las 24 horas hábiles.",
    pleaseSelectTheEventLinkOrCopyTheLinkToYourInternetBrowserTheEventLinkIsLocatedOnTheAboutPageOfTheEventUnderTheLocationSection:
        "Seleccione el enlace del evento o cópielo en su navegador de Internet. El enlace del evento se encuentra en la página Acerca de del evento en la sección Ubicación.",
    productMaterials: "Materiales del producto",
    representingYourSkillsWithABadgeGivesYouAMeansToShareYourAbilitiesOnlineInAWayThatIsSimpleTrustedAndCanBeEasilyVerifiedInRealTimeBadgesProvideEmployersAndPeersConcreteEvidenceOfWhatYouHadToDoToEarnYourCredentialAndWhatYoureNowCapableOfCredlyAlsoOffersLaborMarketInsightsBasedOnYourSkillsYouCanSearchAndApplyForJobOpportunitiesRightThroughCredly:
        "Representar sus habilidades con una insignia le brinda un medio para compartir sus habilidades en línea de una manera simple, confiable y que pueda verificarse fácilmente en tiempo real. Las insignias brindan a los empleadores y colegas evidencia concreta de lo que tuvo que hacer para obtener su credencial y de lo que ahora es capaz de hacer. Credly también ofrece información sobre el mercado laboral, basada en sus habilidades. Puede buscar y solicitar oportunidades laborales directamente a través de Credly.",
    selectTheEducationNetworkHubEitherFromTheQuickLinksOnYourDashboardOrFromTheHamburgerIconOnTheRighthandSideInTheHubYouWillFindALinkToTheNFPACatalogSpecificallyForAENMaterialsWhereYouCanPlaceYourOrder:
        "Seleccione Centro de la Red de Educación Autorizada desde los enlaces rápidos en su panel de control o desde el ícono de hamburguesa en el lado derecho de la pantalla. En el centro encontrará un enlace al catálogo de NFPA específicamente para materiales AEN donde podrá realizar su pedido.",
    thereAreCurrentlyNoOnlineLearningContractsForThisOrganization:
        "Actualmente no existen contratos de aprendizaje en línea para esta organización.",
    thereWasAnIssueLoadingLearners: "Hubo un problema al cargar los alumnos.",
    thereWasAnIssueUpdatingTheProductVersion:
        "Hubo un problema al actualizar la versión del producto.",
    thereWasAProblemReturningPastContractSummaries:
        "Hubo un problema al devolver los resúmenes de contratos pasados.",
    thisNewVersionHasBeenPrepopulatedBasedOffOfThePreviousVersionToUseAsAStartingPoint:
        "Esta nueva versión se ha completado previamente a partir de la versión anterior para usarla como punto de partida.",
    thisProductWillNoLongerBeAvailableToUseForNewEventsIncludingAnyCurrentDraftEvents:
        "Este Producto ya no estará disponible para su uso en nuevos Eventos, incluidos los borradores de Eventos actuales.",
    viewAnnouncement: "Ver anuncio",
    viewVersion: "Ver versión",
    whatAreLaborMarketInsightsAndHowCanIAccessThemThroughCredly:
        "¿Qué es la información sobre el mercado laboral y cómo puedo acceder a ella a través de Credly?",
    whatAreTheBenefitsOfABadge: "¿Cuáles son los beneficios de una insignia?",
    whatIfCourseMaterialsDontArriveOnTimeForClass:
        "¿Qué pasa si los materiales del curso no llegan a tiempo para la clase?",
    whatIfIDontWantMyBadgeToBePublic: "¿Qué pasa si no quiero que mi insignia sea pública?",
    whatIfIHaveFeedbackAboutAnInstructorOrTheEnvironmentOfAnInstructorledEvent:
        "¿Qué pasa si tengo comentarios sobre un instructor o el entorno de un evento guiado por un instructor?",
    whatIfIHaveFeedbackAboutCourseContent:
        "¿Qué pasa si tengo comentarios sobre el contenido del curso?",
    whatIfIHaveQuestionsAboutAnInstructorledEventEgStartTimeLocation:
        "¿Qué pasa si tengo preguntas sobre un evento guiado por un instructor (p. ej., hora de inicio, ubicación)?",
    whatIfIHaveTroubleAccessingMyTrainingInTheLMS:
        "¿Qué pasa si tengo problemas para acceder a mi capacitación en el LMS?",
    whatIfIHaveTroubleAccessingTheLMS: "¿Qué pasa si tengo problemas para acceder al LMS?",
    whatIfIHaveTroubleAccessingTheLMSOrTakingAttendance:
        "¿Qué pasa si tengo problemas para acceder al LMS o para tomar asistencia?",
    whatIfIHaveTroubleSettingUpOrManagingEventsInTheLMS:
        "¿Qué pasa si tengo problemas para configurar o administrar eventos en el LMS?",
    whatIfINeedToCancelrescheduleAnEventDate:
        "¿Qué pasa si necesito cancelar/reprogramar la fecha de un evento?",
    whatIfINeedToChangeOrCancelAClass: "¿Qué pasa si necesito cambiar o cancelar una clase?",
    whatIfINeedToChangeOrCancelAnEvent: "¿Qué pasa si necesito cambiar o cancelar un evento?",
    whatIfINeedToWithdrawFromAnInstructorledCourse:
        "¿Qué pasa si necesito retirarme de un curso guiado por un instructor?",
    whatIfINeedToWithdrawFromAnOnlineLearningCourse:
        "¿Qué pasa si necesito retirarme de un curso de en línea?",
    whatIfTheInstructorDoesntShowUpForClass: "¿Qué pasa si el instructor no se presenta a clase?",
    whatIfThereAreNoInstructorsAvailableOnTheDateOfMyClass:
        "¿Qué pasa si no hay instructores disponibles en la fecha de mi clase?",
    whatIsAnOpenBadge: "¿Qué es una insignia abierta?",
    whatsToKeepSomeoneElseFromCopyingMyBadgeAndUsingIt:
        "¿Qué impide que otra persona copie mi credencial y la use?",
    whatWillHappenToMyNFPABadgeFromBADGR: "¿Qué pasará con mi insignia de NFPA de BADGR?",
    whereAndHowCanIShareMyBadgeThroughCredly:
        "¿Dónde y cómo puedo compartir mi insignia a través de Credly?",
    whereCanIFindMyInstructorRatingForAClass:
        "¿Dónde puedo encontrar mi calificación de instructor para una clase?",
    whereCanIFindThePrimaryContactOfTheTrainingProvider:
        "¿Dónde puedo encontrar el contacto principal del proveedor de capacitación?",
    whereYouCanProcessTheUpdatesThisWillAutomaticallyUpdateYourInformationInTheOnlineLearningPortalWithinHours:
        "Donde podrás procesar las actualizaciones. Esto actualizará automáticamente su información en el Portal de aprendizaje en línea dentro de las 24 horas.",
    whileBadgesAreSimplyDigitalImageFilesTheyAreUniquelyLinkedToDataHostedOnCredlyThisLinkToVerifiedDataMakesThemMoreReliableAndSecureThanAPaperbasedCertificateItAlsoEliminatesThePossibilityOfAnyoneClaimingYourCredentialAndYourAssociatedIdentity:
        "Si bien las insignias son simplemente archivos de imágenes digitales, están vinculadas de forma única a los datos alojados en Credly. Este enlace a datos verificados las hace más confiables y seguras que un certificado en papel. También elimina la posibilidad de que alguien reclame su credencial y su identidad asociada.",
    whoDoIContactIfThereAreTechnologyIssuesDuringClass:
        "¿Con quién me debo comunicar si hay problemas tecnológicos durante la clase?",
    whoDoIContactWithFeedbackOnTheTrainingMaterials:
        "¿Con quién me debo comunicar si tengo comentarios sobre los materiales de capacitación?",
    whatIsCredly: "¿Qué es Credly?",
    whoIsMyPrimaryContactAtNFPA: "¿Quién es mi contacto principal en NFPA?",
    whyWasIOnlyGivenDigitalMaterialsForMyInstructorledClass:
        "¿Por qué solo me dieron materiales digitales para mi clase guiada por un instructor?",
    yesYouCanDownloadYourBadgeFromTheShareBadgePageYourDownloadedBadgeContainsOpenBadgeInfrastructureOBICompliantMetadataEmbeddedIntoTheImageThisAllowsYouToStoreYourBadgeOnOtherOBIcompliantBadgeSitesSuchAsTheBadgrBackpack:
        "Sí, puede descargar su insignia desde la página Compartir insignia. La insignia descargada contiene metadatos compatibles con la Infraestructura de Insignias Abiertas (OBI) incrustados en la imagen. Esto le permite almacenar su insignia en otros sitios de insignias compatibles con la OBI, como Badgr Backpack.",
    youAreActivatingProductVersionVersionId: "Está activando {{productVersionVersionId}}",
    youAreGoingToEditTheProductDetails: "Está por editar los detalles del producto.",
    youAreViewingAnArchivedProduct: "Está viendo un producto archivado.",
    youCanEditTheProductsContentAndAchievements:
        "Puede editar el contenido y los logros del producto.",
    youCanFindTutorialsAndAnswersToAdditionalQuestionsHere:
        "Puede encontrar tutoriales y respuestas a preguntas adicionales aquí:",
    youCannotSaveThisVersionUntilAllSectionsAreComplete:
        "No puede guardar esta versión hasta que todas las secciones estén completas.",
    youCanShareYourBadgeDirectlyFromCredlyToLinkedInTwitterAndFacebookOverEmailEmbeddedInAWebsiteOrInYourEmailSignature:
        "Puede compartir su insignia directamente desde Credly en LinkedIn, Twitter y Facebook; por correo electrónico; integrada en un sitio web; o en su firma de correo electrónico.",
    youCanUpdateYourInformationSuchAsTheSpellingOfYourNameOrYourEmailAddressByGoingToMyProfileWhichCanBeAccessedByUsingTheHamburgerIconWithinTheOnlineLearningPortalALinkWithinTheBasicInformationSectionWillDirectYouToYourNFPAAccountAt:
        "Puede actualizar su información yendo a Mi perfil, al que se puede acceder utilizando el icono de hamburguesa (☰) en el Portal de aprendizaje en línea. Un enlace dentro de la sección de Información Básica lo dirigirá a su cuenta de NFPA en",
    youHaveNoCurrentInstructorLedTrainings:
        "Actualmente no tiene entrenamientos impartidos por un instructor",
    youHaveNoCurrentOnlineTrainings: "Actualmente no tiene entrenamientos en línea",
    youHaveNoPastTrainings: "No tiene entrenamientos pasados",
    yourInstructorProfileCanBeViewedByNFPAStaffAsWellAsAENProvidersYourProfileIsUsedToAssistInTheSelectionOfInstructorAssignments:
        "El personal de NFPA y los proveedores AEN pueden ver su perfil de instructor. Su perfil se utiliza para ayudar en la selección de asignaciones de instructor.",
    yourOrganizationHasNoCurrentInstructorLedTrainings:
        "Su organización no cuenta actualmente con entrenamientos impartidos por instructores",
    yourOrganizationHasNoCurrentOnlineTrainings:
        "Su organización no cuenta actualmente con entrenamientos en línea",
    yourOrganizationHasNoPastInstructorLedTrainings:
        "Su organización no cuenta con entrenamientos pasados impartidos por instructores",
    youveCreatedVersionVersionNumber: "Ha creado la versión {{versionNumber}}",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToEventsThatAreCurrentlyInProgress:
        "Tendrá la opción de aplicar esta nueva versión a los eventos que están actualmente en curso.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgress:
        "Tendrá la opción de aplicar esta nueva versión a los alumnos que están actualmente en curso.",
    activateVersion: "Activar versión",
    activatingCourseFailed: "Error al activar el curso.",
    activatingProductFailed: "Error al activar el producto.",
    addAnInstructor: "Agregar un instructor",
    addAnOrganization: "Agregar una organización",
    addInstructors: "Agregar instructores",
    advanceYourCareerWithTraining:
        "Avance en su carrera profesional con capacitación directa de la fuente.",
    AnErrorOccurredWhileDownloadingTheContractCertificates:
        "Se produjo un error al descargar los certificados del contrato.",
    AnErrorOccurredWhileDownloadingTheEventCertificates:
        "Se produjo un error al descargar los certificados del evento.",
    announcementMustHaveAnEnglishTitleAndEnglishBody:
        "El anuncio debe tener el título y el cuerpo en inglés.",
    applyToLearners: "Aplicar a los alumnos",
    archivingAnnouncementFailed: "Error al archivar el anuncio.",
    archivingCourseFailed: "Error al archivar el curso.",
    archivingProductFailed: "Error al archivar el producto.",
    AreYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "¿Está seguro de que desea cancelar sin guardar los cambios?",
    assessmentsWillBeAvailableToCompleteOnLastEventDate:
        "Los exámenes estarán disponibles para completarse el {{lastEventDate}}.",
    attended: "Asistió",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNoStartedWillReceiveThisNewVersion:
        "Al crear esta nueva versión, los alumnos que hayan completado la versión anterior permanecerán en esa versión. Todos los alumnos que se hayan inscrito en la versión anterior, pero que no hayan comenzado, recibirán esta nueva versión.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionHowWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVesionWillResetTheLearnersProgressInThisCourse:
        "Al crear esta nueva versión, los alumnos que hayan completado la versión anterior permanecerán en esa versión. Todos los alumnos que se hayan inscrito en la versión anterior, pero que no hayan comenzado, recibirán esta nueva versión. ¿Cómo le gustaría aplicar esta nueva versión a los alumnos que están con la versión actual? La asignación de la nueva versión restablecerá el avance del alumno en el curso.",
    byCreatingThisNewVersionLearnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersionAnyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersionYouWillBeAbleToChooseHowThisVersionIsAppliedToLearnersThatAreInProgressOnTheCurrentVersion:
        "Al crear esta nueva versión, los alumnos que hayan completado la versión anterior permanecerán en esa versión. Todos los alumnos que se hayan inscrito en la versión anterior, pero que no hayan comenzado, recibirán esta nueva versión. Podrá elegir cómo aplicar esta versión a los alumnos que están con la versión actual.",
    cannotEmulateUser: "No se puede emular el usuario.",
    certificateExpDateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString:
        "Vto. del certificado {{DateUtilsFormatDatetrueenrollmentCertificateExpirationDateToString}}",
    certificateExpirationDateVariable:
        "Fecha de vencimiento del certificado: {{certificateExpirationDate}}",
    changeDescription: "Cambiar descripción",
    completionDateVariable: "Fecha de finalización: {{completionDate}}",
    contactInformationHidden: "Información de contacto oculta",
    contactNFPA: "Contactar a la NFPA",
    continue: "Continuar",
    courseCompletionCriteria: "Criterios de finalización del curso",
    courseCompletionCriteriaILT:
        "Curso guiado por un instructor: Deberá asistir a todas las sesiones y completar la evaluación para recibir un certificado de finalización.",
    courseCompletionCriteriaOLL:
        "Curso en línea: Deberá completar todas las actividades y la evaluación para recibir un certificado de finalización.",
    createAnAccount: "Crear una cuenta",
    deactivatingCourseFailed: "Error al desactivar el curso.",
    deactivatingProductFailed: "Error al desactivar el producto.",
    deleteFile: "Eliminar archivo",
    downloadCertificates: "Descargar certificados",
    duplicateEmailsWillBeRemovedFromTheFileImportAndWillNotBeIncludedOnTheImportSummaryReport:
        "Los correos electrónicos duplicados se eliminarán de la importación de archivos y no se incluirán en el informe Resumen de importación.",
    emailMustBeInFirstColumn: "El correo electrónico debe estar en la primera columna.",
    enrolledLearners: "Alumnos inscritos",
    enterSearchCriteria: "Ingrese los criterios de búsqueda...",
    eventDoesNotExist: "El evento no existe.",
    eventMustHaveANameAndType: "El evento debe tener un nombre y tipo.",
    expExpirationDate: "VTO. {{expirationDate}}",
    failedToRetrieveContents: "No se pudo recuperar el contenido.",
    failedToRetrieveInstructors: "No se pudo recuperar los instructores.",
    fileSizeIsTooLarge: "El tamaño del archivo es demasiado grande.",
    generateAndDownloadCertificate: "Generar y descargar certificado",
    gettingProductsCountFailed: "Error al obtener el recuento de productos.",
    hour: "Hora",
    howToFacilitateLearningTheNFPAWay: "Cómo facilitar el aprendizaje al estilo de la NFPA",
    howWouldYouLikeToApplyThisNewVersionToLearnersWhoAreInProgressOnTheCurrentVersionAssigningTheNewVersionWillResetTheLearnersProgressInTheCourse:
        "¿Cómo le gustaría aplicar esta nueva versión a los alumnos que están con la versión actual? La asignación de la nueva versión restablecerá el avance del alumno en el curso.",
    IApproveAnException: "Apruebo una excepción.",
    instructors: "Instructores",
    isThisCertificateForHotWork: "¿Este certificado es para trabajo en caliente?",
    lastSync: "Última sincronización",
    launchNotYetImplementedForOnlineTraining:
        "Lanzamiento aún no implementado para la capacitación en línea.",
    learningPortal: "Portal de capacitación",
    nfpaLearningPortal: "Portal de capacitación de la NFPA",
    notSeeingChangesReflectedAbove: "¿No ve los cambios recientes reflejados?",
    notSeeingLocationOrContactInformation:
        "¿No ve los cambios recientes de ubicación o información de contacto reflejados?",
    or: "o bien",
    packageImportStatus: "Estado de importación del paquete",
    privacyPolicy: "Política de privacidad",
    productMustHaveANameTypeAndProductType:
        "El producto debe tener un nombre, tipo y tipo de producto.",
    ReasonForException: "Motivo de la excepción",
    replaceFile: "Reemplazar el archivo",
    saveChangesSummary: "Guardar resumen de cambios",
    selectAssignment: "Seleccionar asignación",
    selectInstructors: "Seleccionar instructores",
    signIn: "Iniciar sesión",
    startTime: "Hora de inicio",
    syncData: "Sincronizar datos",
    termsOfUse: "Términos de uso",
    theEvaluationWillBeAvailableToCompleteOnLastEventDate:
        "La evaluación estará disponible para completarse el {{lastEventDate}}.",
    thereWasAnIssueCreatingTheNewVersion: "Hubo un problema al crear la nueva versión.",
    thereWasAnIssueUpdatingTheCourseDetails:
        "Hubo un problema al actualizar los detalles del curso.",
    thereWasAnIssueUpdatingTheCourseVersion: "Hubo un problema al actualizar la versión del curso.",
    thereWasAProblemReturningCourseDetailsArchive:
        "Hubo un problema al devolver el archivo de detalles del curso.",
    thisEventWasCanceledCanceledOnByCanceledByNameCanceledById:
        "Este evento fue cancelado el {{canceledOn}} por {{canceledByName}} {{canceledById}}",
    thisUsersInstructorRoleWasRemovedDeletedOnByFirstAndLastNameThisContentIsProvidedForHistoricalContextOnly:
        "La función de instructor de este usuario fue eliminada el {{deletedOn}} por {{firstAndLastName}}. Este contenido se proporciona únicamente para el contexto histórico.",
    trainingAccessExpDateUtilsFormatDatetrueenrollmentExpirationDateToString:
        "Vto. del acceso a la capacitación {{DateUtilsFormatDatetrueenrollmentExpirationDateToString}}",
    typeToSearchByInstructorNameOrID:
        "Escribir para buscar por nombre o identificación del instructor",
    typeToSearchByOrganizationNameOrID:
        "Escribir para buscar por nombre o identificación de la organización",
    unableToUpdateStatus: "No se puede actualizar el estado.",
    unsupportedCultureCodeLocation: "Ubicación del código cultural no compatible.",
    versionStatus: "Estado de la versión",
    withdrawingEnrollmentFailed: "Error al retirar la inscripción.",
    youAreActivatingVersionCourseVersionVersionId:
        "Está activando la versión {{courseVersionVersionId}}",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToACourseOrSeries:
        "Está creando un {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. Para cambiar a un curso o serie, ",
    youAreBuildingAProductCourseTypeDisplayNamesproductOnlineLearningTypeToChangeToALearningPath:
        "Está creando un {{ProductCourseTypeDisplayNamesproductOnlineLearningType}}. Para cambiar a una ruta de aprendizaje, ",
    youAreEditingTheCurrentVersion: "Está editando la versión actual",
    youMustAddAScormPackageToCreateANewVersion:
        "Debe agregar un paquete Scorm para crear una nueva versión.",
    yourNewVersionHasBeenCreatedAndTheZIPPackageIsCurrentlyProcessingOnceTheUploadIsCompleteMoveForwardToActivatingTheVersionToApplyIt:
        "Se ha creado una nueva versión y el paquete ZIP se está procesando. Una vez que se complete la carga, continúe con la activación de la versión para aplicarla.",
    youveCreatedVersionCourseVersionVersionId: "Ha creado la versión {{courseVersionVersionId}}",
    youWereWithdrawnFromThisTrainingOnWithdrawnDateForAnyQuestionsPleaseContactNFPASupport:
        "Se lo retiró de esta capacitación el {{withdrawnDate}}. Si tiene alguna pregunta, comuníquese con el soporte de la NFPA.",
    fileFormatMustBePropsFileFormatAndLessThanPropsMaxFileSizeInMBMB:
        "El formato de archivo debe ser {{propsFileFormat}} y pesar menos de {{propsMaxFileSizeInMB}} MB.",
    about: "Acerca de",
    aboutMe: "Acerca de mí",
    aboutThisCourse: "Acerca de este curso",
    aboutThisTraining: "Acerca de esta capacitación",
    aboutThisUnit: "Acerca de esta unidad",
    accessLocked: "Acceso bloqueado",
    account: "Cuenta",
    accountFirstName: "Nombre de la cuenta",
    accountLastName: "Apellido de la cuenta",
    achievements: "Logros",
    aContractAlreadyExistsWithThisNumber: "Ya existe un contrato con este número.",
    action: "Acciones",
    activateCourse: "Activar curso",
    activateProduct: "Activar producto",
    activatingTheProductFailedPleaseEnsureThereIsAProductNameDescriptionAndCoursesConfigured:
        "Error al activar el producto. Asegúrese de que el nombre, la descripción y los cursos del producto estén configurados.",
    activationSummary: "Resumen de la activación",
    active: "Activo",
    activity: "Actividad",
    add: "Agregar",
    addAContract: "Agregar un contrato",
    addADay: "Agregar un día",
    addAnEvent: "Agregar un evento",
    addAProduct: "Agregar un producto",
    addASession: "Agregar una sesión",
    addAssessment: "Agregar una evaluación",
    addAssessments: "Agregar evaluaciones",
    addAttempt: "Agregar intento",
    addBulkEnrollment: "Agregar inscripción masiva",
    addContent: "Agregar contenido",
    addContract: "Agregar contrato",
    addContracts: "Agregar contratos",
    addCourses: "Agregar cursos",
    addCoursesToStartBuildingOutTheUnit: "Agregar cursos para empezar a construir la unidad",
    addEnProvider: "Agregar proveedor AEN",
    addEnrollment: "Agregar inscripción",
    addEvents: "Agregar eventos",
    addInstructor: "Agregar instructor",
    additionalDetails: "Detalles adicionales",
    additionalMaterialHasBeenAddedSpecificToTheContract:
        "Se ha agregado material adicional específico del contrato.",
    additionalRequirements: "Requisitos adicionales",
    AdditionalResourcesMayBeFoundWithinEachCourse:
        "Cada curso puede contener recursos adicionales.",
    addLeaner: "Agregar alumno",
    addLearners: "Agregar alumnos",
    addNew: "Agregar nuevo",
    addNewAnnouncement: "Agregar nuevo anuncio",
    addNewContract: "Agregar nuevo contrato",
    addNewContractOrganization: "Agregar nueva organización del contrato",
    addNewCourse: "Agregar nuevo curso",
    addNewENProvider: "Agregar nuevo proveedor AEN",
    addNewEvent: "Agregar nuevo evento",
    addNewProduct: "Agregar nuevo producto",
    addNewUser: "Agregar nuevo usuario",
    addOrganization: "Agregar organización",
    addOrganizations: "Agregar organizaciones",
    addPackage: "Agregar paquete",
    addProduct: "Agregar producto",
    addProducts: "Agregar productos",
    address: "Dirección",
    addressLine1: "Línea 1 de dirección",
    addressLine1IsRequired: "La línea 1 de dirección es obligatoria.",
    addressLine2: "Línea 2 de dirección",
    addToSchedule: "Agregar al cronograma",
    addUnit: "Agregar unidad",
    addUser: "Agregar usuario",
    administratorSlashInstructor: "Administrador/instructor",
    aenAdministrator: "Administrador AEN",
    aenProvider: "Proveedor AEN",
    alabama: "Alabama",
    alaska: "Alaska",
    all: "Todas",
    allDay: "Todo el día",
    allRowsMustHaveCompleteData: "Todas las filas deben tener datos completos.",
    allTrainingsOllAndILT: "Todos los entrenamientos (OLL e ILT)",
    allTrainingsReport: "Informe de todos los entrenamientos",
    allUsersReport: "Informe de todos los usuarios",
    alreadyEnrolled: "Ya inscrito",
    anAccountWithThisEmailAlreadyExists: "Ya existe una cuenta con este correo electrónico.",
    andDisplayedAs: "Y se muestra como...",
    anErrorOccurredCancelingThisEvent: "Se produjo un error al cancelar este evento.",
    anErrorOccurredPublishingThisEvent: "Se produjo un error al publicar este evento.",
    anErrorOccurredSavingYourSelection: "Se produjo un error al guardar su selección.",
    anErrorOccurredUpdatingThisEvent: "Se produjo un error al actualizar este evento.",
    anErrorOccurredWhileAddingTheAttempt: "Se produjo un error al agregar el intento.",
    anErrorOccurredWhileSavingTheAttempt: "Se produjo un error al guardar el intento.",
    announcementBody: "Cuerpo del anuncio",
    announcementName: "Nombre del anuncio",
    announcements: "Anuncios",
    announcementTitle: "Título del anuncio",
    answer: "Respuesta",
    anyLearnersWhoHaveEnrolledInThePreviousVersionButHaveNotStartedWillReceiveThisNewVersion:
        "Todos los alumnos que se hayan inscrito en la versión anterior, pero que no hayan comenzado, recibirán esta nueva versión.",
    approvedEventScheduleExceptionGetLastUpdatedText:
        "{{eventScheduleExceptionGetLastUpdatedText}} aprobado",
    approvedInstructorsForThisProduct: "Instructores aprobados para este producto",
    approvedToTeach: "Centro de la Red de Educación Autorizada",
    arabic: "Árabe",
    archive: "Archivo",
    archiveCourse: "Archivar curso",
    archived: "Archivado",
    archivedAnnouncements: "Anuncios archivados",
    archiveProduct: "Archivar producto",
    areYouSureYouWantToClearAllLanguageData:
        "¿Está seguro de que desea borrar todos los datos de idioma?",
    areYouSureYouWantToDeleteThisEvent: "¿Está seguro de que desea eliminar este evento?",
    areYouSureYouWantToExit: "¿Está seguro de que desea salir?",
    areYouSureYouWantToRemoveCourseContentToDeleteContentName:
        "¿Está seguro de que desea eliminar {{courseContentToDeleteContentName}}?",
    areYouSureYouWantToRemoveEventDayToDeleteGetEventDateTextFromYourSchedule:
        "¿Está seguro de que desea eliminar {{eventDayToDeleteGetEventDateText}} de su cronograma?",
    areYouSureYouWantToRemoveProductContentToDeleteContentName:
        "¿Está seguro de que desea eliminar {{productContentToDeleteContentName}}?",
    areYouSureYouWantToRemoveProductScormPackageToDeleteScormPackageName:
        "¿Está seguro de que desea eliminar {{productScormPackageToDeleteScormPackageName}}?",
    areYouSureYouWantToRemoveUnit: "¿Está seguro de que desea eliminar {{unit}}?",
    areYouSureYouWantToRemoveUnitCourseToDeleteCourseName:
        "¿Está seguro de que desea eliminar {{unitCourseToDeleteCourseName}}?",
    areYouSureYouWantToWithdrawThisLearner: "¿Está seguro de que desea retirar a este alumno?",
    areYouSureYouWouldLikeToCancelWithoutSavingYourChanges:
        "¿Está seguro de que desea cancelar sin guardar los cambios?",
    areYouSureYouWouldLikeToChangeTheAssociatedProduct:
        "¿Está seguro de que desea cambiar el Producto asociado? ",
    areYouSureYouWouldLikeToChangeTheEventSalesType:
        "¿Está seguro de que desea cambiar el Tipo de venta del evento?",
    areYouSureYouWouldLikeToChangeYourScheduleToAllDayEvents:
        "¿Está seguro de que desea cambiar su cronograma a eventos de todo el día?",
    areYouSureYouWouldLikeToLeaveWithoutSavingYourChanges:
        "¿Está seguro de que desea salir sin guardar los cambios?",
    areYouSureYouWouldLikeToStartOver: "¿Está seguro de que desea empezar de nuevo?",
    arizona: "Arizona",
    arkansas: "Arkansas",
    arrowColumn: "Columna de flecha",
    aShortDescriptionAboutYou: "Una breve descripción sobre usted",
    assessment: "Examen",
    assessmentDeletedSuccessfully: "Evaluación eliminada correctamente",
    assessmentMarkedComplete: "Evaluación marcada como completa",
    assessmentName: "Nombre de la evaluación",
    assessmentResult: "Resultado de la evaluación",
    assessmentResults: "Resultados de la evaluación",
    assessments: "Exámenes",
    assessmentScore: "Puntuación de la evaluación",
    assignATraining: "Asignar un entrenamiento",
    assignEventContactInformation: "Asignar información de contacto del evento",
    assignments: "Asignaciones",
    assignTraining: "Asignar entrenamiento",
    associatedEnProvider: "Proveedor AEN asociado",
    associatedOrganization: "Organización asociada",
    associatedProduct: "Producto asociado",
    associatedProductCannotBeEditedAfterContractIsCreated:
        "El producto asociado no puede editarse después de creado el contrato.",
    associatedProductSelection: "Selección de productos asociados",
    associatedUsers: "Usuarios asociados",
    associateThisUserToAnExistingOrganization:
        "Asociar a este usuario a una organización existente",
    asThePrimaryMethodToContactTheEventOrganizer:
        "como método principal para contactar al organizador del evento",
    asYouAddDaysAndSessionsToYourEventScheduleTheyWillAppearHere:
        "A medida que agregue días y sesiones a su cronograma de eventos, aparecerán aquí.",
    attempt: "Intento",
    attemptCreatedSuccessfully: "Intento creado correctamente",
    attemptCreationFailed: "Error al crear intento",
    attempted: "Intentado",
    attempts: "Intentos",
    attemptUpdatedSuccessfully: "Intento actualizado correctamente",
    attendance: "Asistencia",
    attendanceTrackedSuccessfully: "El seguimiento de asistencia se realizó con éxito.",
    attendanceTrackingIsNotAvailableUntilTheDayOfTheEvent:
        "El seguimiento de asistencia no está disponible hasta el día del evento.",
    attendanceTrackingIsNotAvailableUntilTheStartOfTheEvent:
        "El seguimiento de asistencia no está disponible hasta el inicio del evento.",
    authenticateAs: "Autenticar como",
    availabilityConfirmed: "Disponibilidad confirmada",
    availableForAen: "Disponible para AEN",
    backToAll: "Volver a Todas las",
    backToAllAssessments: "Volver a Todas las evaluaciones",
    backToAllAttendance: "Volver a Todas las asistencias",
    backToAllInstructorLedEventContracts:
        "Volver a Todos los contratos de eventos impartidos por instructores",
    backToAllOnlineLearningContracts: "Volver a Todos los contratos de aprendizaje en línea",
    backToAllTrainings: "Volver a Todos los entrenamientos",
    backToAllVersions: "Volver a Todas las versiones",
    backToAssessmentResults: "Volver a Resultados de las evaluaciones",
    backToCourse: "Volver a Curso",
    backToEvaluationSummary: "Volver a Resumen de la evaluación",
    backToParent: "Volver a {{parent}}",
    backToSyllabus: "Volver a Plan de estudios",
    badge: "Insignia",
    badgeName: "Nombre de la insignia",
    badges: "Insignias",
    basicInformation: "Información básica",
    beforeChoosingYourInstructor: "antes de elegir a su instructor",
    bio: "Biografía",
    browseCatalog: "Explorar Catálogo",
    by: "Al/Por",
    byDoingSoAnyInformationEnteredInTheEventsDetail:
        "Al hacerlo, se restablecerá cualquier información ingresada en las secciones Detalles del evento, Programación e Instructor de AEN.",
    byDoingSoAnyInformationEnteredInTheSchedule:
        "Al hacerlo, se restablecerá toda la información ingresada en las secciones Cronograma e Instructor de AEN.",
    byDoingSoEventTimesWillBeRemoved: "Al hacerlo, se eliminarán los horarios de los eventos.",
    byReplacingTheAssociatedENProviderAnyInformationEnteredForEventContactInformationWillBeReset:
        "Al reemplazar el proveedor de AEN asociado, se restablecerá cualquier información ingresada en Información de contacto del evento.",
    byReplacingTheAssociatedOrganizationAnyInformationEnteredForEventContactInformationWillBeReset:
        "Al reemplazar la organización asociada, se restablecerá cualquier información ingresada en Información de contacto del evento.",
    byReplacingTheAssociatedProductAnyInformationEnteredInTheScheduleAndInstructorSectionsWillBeReset:
        "Al reemplazar el producto asociado, se restablecerá cualquier información ingresada en las secciones Cronograma e Instructor.",
    california: "California",
    cancel: "Cancelar",
    canceled: "Cancelado",
    cancelEdit: "Cancelar Editar",
    cancelEvent: "Cancelar evento",
    cancellationMessage: "Mensaje de cancelación",
    cancelledonBYCancelledByNameCancelledById:
        "{{cancelledOn}} POR {{cancelledByName}} {{cancelledById}}",
    cancelVersion: "Cancelar versión",
    ceCREDITS: "Créditos CE",
    certificate: "Certificado",
    certificateExp: "Vto. del certificado",
    certificateExpiration: "Vencimiento del certificado",
    certificateNameWarning:
        "Este es el nombre que aparecerá en los certificados que obtenga en el LMS. Si por algún motivo necesita que se le vuelva a emitir un certificado, comuníquese con la NFPA.",
    certificationPrep: "Prep. de la certificación",
    ceus: "CEU",
    ceusEnrollmentProductCreditHoursToString: "CEU: {{enrollmentProductCreditHoursToString}}",
    ceusEventCEUs: "CEU: {{eventCEUs}}",
    ceusEventProductCreditHoursToString: "CEU: {{eventProductCreditHoursToString}}",
    ceusProductCreditHoursToString: "CEU: {{productCreditHoursToString}}",
    ceusWillBeAvailableForDownloadOnceYouHaveCompletedAllRequiredElementsOfTheTraining:
        "estará disponible para su descarga una vez que haya completado todos los elementos requeridos de la capacitación.",
    change: "Cambiar",
    changedBy: "Cambiado por",
    changeHistory: "Historial de cambios",
    changeLog: "Registro de cambios",
    changeOrder: "Cambiar orden",
    changeStatus: "Cambiar estado",
    changeStatusTo: "Cambiar estado a",
    changesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageCreateANewCourseVersion:
        "No puede realizar cambios en el paquete ZIP al editar la versión actual del curso. Para cambiar el paquete ZIP, cree una nueva versión del curso.",
    changesWereMadeToThisSection: "Se realizaron cambios en esta sección.",
    changesWillBeAppliedToAllLearnersOnThisVersion:
        "Los cambios se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios se aplicarán a todos los alumnos, independientemente de la versión o del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    changesWillBeAppliedToAllLearnersRegardlessOfVersionOrCompletionStatusAndWillNotImpactOrRestALearnersProgress:
        "Los cambios se aplicarán a todos los alumnos, independientemente de la versión o del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    checkBackSoon: "Vuelva a consultar pronto.",
    checklist: "Lista de verificación",
    checkmarkChecked: "Marca de verificación marcada",
    checkmarkUnchecked: "Marca de verificación no marcada",
    chooseDate: "Elegir fecha",
    chooseFile: "Elegir archivo",
    cityIsRequired: "Se requiere la ciudad.",
    citySlashTown: "Ciudad",
    clearAll: "Borrar todo",
    clearAllFilters: "Borrar todos los filtros",
    clientAdmin: "Admin de clientes",
    clientAdminRoleCurrentlyHasAnActiveContractInTheLMS:
        "La función de Admin de clientes actualmente tiene un contrato activo en el LMS.",
    close: "Cerrar",
    colorado: "Colorado",
    community: "Comunidad",
    complete: "Completado",
    completed: "Completado",
    completedTrainings: "Entrenamientos completados",
    completeUnitBeforeMovingForward: "Completar unidad antes de seguir avanzando",
    completeUnitsInOrder: "Completar unidades en orden",
    completion: "Finalización",
    completionDate: "Fecha de finalización",
    confirm: "Confirmar",
    connecticut: "Connecticut",
    contact: "Contacto",
    contactInfo: "Información de contacto",
    content: "Contenido",
    contentAudience: "Audiencia del contenido",
    contentForAdministatorAndInstructor: "Contenido para administrador e instructores",
    contentForParticipants: "Contenido para participantes",
    continueToEdit: "Continuar con Editar",
    continueToVersion: "Continuar con Versión",
    contract: "Contrato",
    contractContractContractNumber: "Contrato n.º {{contractContractNumber}}",
    contractDate: "Fecha del contrato",
    contractEvent: "Evento del contrato",
    contractIDProductIDAndFileAreNeeded:
        "Se necesitan la identificación del contrato, la identificación del producto y el archivo.",
    contractInformation: "Información del contrato",
    contractNumber: "Número de contrato",
    contractOrganization: "Organización del contrato",
    contractOrganizationInformation: "Información de la organización del contrato",
    contractOrganizations: "Organizaciones del contrato",
    contracts: "Contratos",
    correct: "Correcto",
    country: "País",
    countryIsRequired: "Se requiere el país.",
    course: "Curso",
    courseAbbreviated: "C",
    courseDescription: "Descripción del curso",
    courseDetails: "Detalles del curso",
    courseEucUnitCourseSortOrder: "Curso {{eucUnitCourseSortOrder}}",
    courseId: "Identificación del curso",
    courseIdIsAutomaticallyGenerated:
        "La identificación del curso se genera automáticamente y no se puede editar.",
    courseIsNowActive: "El curso ya está activo.",
    courseIsNowArchived: "El curso se ha archivado.",
    courseIsNowDeactivated: "El curso ya está desactivado.",
    courseMaterials: "Materiales del curso",
    courseMustHaveANameAndType: "El curso debe tener un nombre y tipo.",
    courseName: "Nombre del curso",
    courseOrSeries: "Curso o serie",
    courseProductReturnedAnUnexpectedResult: "El producto del curso dio un resultado inesperado.",
    courseResources: "Recursos del curso",
    courses: "Cursos",
    courseTopicSelection: "Selección del tema del curso",
    courseWasUndefined: "El curso no estaba definido.",
    createAContract: "Crear un contrato",
    createACourse: "Crear un curso",
    createANewEvent: "Crear un nuevo evento",
    createANewProduct: "Crear un nuevo producto",
    createANewVersion: "Crear una nueva versión",
    createCourse: "Crear curso",
    createdondatetimeByThisCreatedByGetFirstAndLastName:
        "{{createdOnDateTime}} por {{thisCreatedByGetFirstAndLastName}}",
    createEvent: "Crear evento",
    createNewVersion: "Crear nueva versión",
    createProduct: "Crear producto",
    createScormPackageFailed: "Error al crear el paquete SCORM",
    createUnit: "Crear unidad",
    createVersion: "Crear versión",
    createYourFirstUnitToStartOrganizingCourses:
        "Cree su primera unidad para empezar a organizar cursos.",
    creatingAnEnrollmentFailed: "Error al crear una inscripción",
    creditHour: "Hora de crédito",
    creditHours: "Horas de crédito",
    currentAndUpcomingTrainings: "Entrenamientos actuales y futuros",
    currentAnnouncements: "Anuncios actuales",
    currentRequiredTrainings: "Entrenamientos requeridos actualmente",
    currentStatus: "Estado actual",
    currentTrainings: "Entrenamientos actuales",
    customizedContact: "Contacto personalizado",
    customizeForThisEvent: "Personalizar para este evento",
    dashboard: "Panel",
    date: "Fecha",
    dateCreated: "Fecha de creación",
    dateOfCompletion: "Fecha de finalización",
    dateRan: "Fecha de ejecución",
    dateRange: "Rango de fechas",
    dateXXXXXXXX: "Fecha: XX/XX/XXXX",
    day: "Día",
    dayName: "Nombre del día",
    deactivateCourse: "Desactivar curso",
    deactivateProduct: "Desactivar producto",
    deactivateTheCourse: "Desactivar el curso",
    deactivateTheProduct: "Desactivar el producto",
    deactivatingTheProductFailed: "Error al desactivar el producto",
    default: "Predeterminado",
    defaultContactInformation: "Información de contacto predeterminada",
    defaultEventContactInfo: "Información de contacto del evento predeterminada",
    defaultEventContactInformation: "Información de contacto del evento predeterminada",
    defaultEventInformation: "Información del evento predeterminada",
    defaultEventLocation: "Ubicación del evento predeterminada",
    defaultEventTimezone: "Zona horaria del evento predeterminada",
    delaware: "Delaware",
    delete: "Eliminar",
    deleteAssessment: "Eliminar evaluación",
    deleteEvent: "Eliminar evento",
    deleteSession: "Eliminar sesión",
    describeYourChanges: "Describa sus cambios",
    description: "Descripción",
    descriptionColumn: "Columna de descripción",
    deselect: "Deseleccionar",
    detailedBreakdown: "Desglose detallado",
    displayType: "Tipo de visualización",
    domestic: "Doméstico",
    done: "Listo",
    doubleDash: "--",
    downloadButtonForName: "Botón de descarga para {{name}}",
    downloadButtonForPropsContentItemName: "Botón de descarga para {{propsContentItemName}}",
    downloadCertificate: "Descargar certificado",
    downloadCSVImportSummaryFile: "Descargar archivo de resumen de importación CSV",
    downloadLinkForCSVImportSummaryFile:
        "Descargar enlace para archivo de resumen de importación CSV",
    downloadResume: "Descargar currículum",
    downloadsForAdministratorAndInstructor: "Descargas para el administrador e instructor",
    downloadsForParticipants: "Descargas para los participantes",
    doYouWantToAssociateABadge: "¿Desea asociar una insignia con este producto?",
    draft: "Borrador",
    duration: "Duración",
    duringThisTimePeriod: "Durante este período",
    edit: "Editar",
    editAnInstructorLedTrainingProduct:
        "Editar un producto de entrenamiento impartido por un instructor",
    editAnnouncement: "Editar anuncio",
    editAnOnlineLearningProduct: "Editar un producto de aprendizaje en línea",
    editAttempt: "Editar intento",
    editCEUs: "Editar CEU",
    editContract: "Editar contrato",
    editContractOrganization: "Editar organización del contrato",
    editCourse: "Editar curso",
    editCourseDetails: "Editar detalles del curso",
    editDay: "Editar día",
    editEnProvider: "Editar proveedor AEN",
    editEvent: "Editar evento",
    editingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "Editar un intento puede actualizar el progreso del alumno.",
    editingMode: "Modo edición",
    editLearner: "Editar alumno",
    editName: "Editar nombre",
    editProduct: "Editar producto",
    editUnit: "Editar unidad",
    editUser: "Editar usuario",
    editVersion: "Editar versión",
    educationNetwork: "Red de educación",
    educationNetworkEvent: "Evento de la Red de educación",
    educationNetworkHub: "Centro de la Red de Educación Autorizada",
    aenHub: "Centro AEN",
    educationNetworkResources: "Recursos de la Red de educación",
    electrical: "Eléctrico",
    email: "Correo electrónico",
    emailAddress: "Dirección de correo electrónico",
    emailIsInAValidFormatExampleEmaildomain:
        "El correo electrónico tiene un formato válido (ejemplo: correoelectrónico@dominio).",
    emergencyResponse: "Respuesta a emergencias",
    emptyDate: "00/00/0000",
    emptyString: "",
    emulateUser: "Emular usuario",
    emulationNotAvailableForAdministrators: "Emulación no disponible para administradores",
    emulationNotAvailableForAdmins: "Emulación no disponible para administradores",
    endingEmulationSession: "La sesión de emulación está finalizando.",
    endSession: "Finalizar sesión",
    endTime: "Hora de finalización",
    english: "Inglés",
    enInstructor: "Instructor de AEN",
    enProductCatalog: "Catálogo de productos AEN",
    enProvider: "Proveedor AEN",
    enProviderEvent: "Evento de proveedores AEN",
    enProviderInformation: "Información del proveedor AEN",
    enProviderName: "Nombre del proveedor AEN",
    enProviders: "Proveedores AEN",
    enProvidersOrgName: "Nombre de la organización del proveedor AEN",
    enResource: "Recurso AEN",
    instructorAssessment: "Evaluación del instructor",
    enrolled: "Inscrito",
    enrolledAndCompleted: "Inscrito y completado",
    enrolledButNotStartedEnrolledAndInProgress:
        "Inscrito pero todavía no empezó/Inscrito y en curso",
    enrolledFirstName: "Nombre de pila de la persona inscrita",
    enrolledLastName: "Apellido de la persona inscrita",
    enrolledName: "Nombre de la persona inscrita",
    enrollment: "Inscripción",
    enrollmentCSVRequirements: "Requisitos CSV de inscripción:",
    enrollmentHistory: "Historial de inscripción",
    enrollmentImportStatus: "Estado de importación de inscripción:",
    enrollmentImportSummaryColon: "Resumen de importación de inscripción:",
    enrollmentLimit: "Límite de inscripción",
    enrollmentLimitCannotBeLessThanTheCurrentEnrollmentCount:
        "El límite de inscripción no puede ser menor que el recuento de inscripción actual.",
    enrollmentReport: "Informe de inscripción",
    enrollmentsForAEvent: "Inscripciones para un evento",
    enrollmentsForProductName: "Inscripciones para {{productName}}",
    enrollmentsForProductNameEventName: "Inscripciones para {{productName}} - {{eventName}}",
    enrollmentStatusWillAppearAsInvitePending:
        'El estado de inscripción aparecerá como "Invitación pendiente" si el alumno no tiene un perfil de NFPA completo. Una vez agregado a la inscripción, el alumno recibirá un correo electrónico en el que se le indicará que termine de configurar su perfil de NFPA. Una vez completado, aparecerá el nombre del alumno en lugar de "Invitación pendiente".',
    enrollmentSuccessful: "Inscripción exitosa.",
    enrollNewTraining: "Inscribirse en un nuevo entrenamiento",
    enrollTraining: "Inscribir entrenamiento",
    enterAdditionalDetails: "Ingresar detalles adicionales",
    enterAdditionalRequirements: "Ingresar requisitos adicionales",
    enterAddressLine1: "Ingresar línea 1 de dirección",
    enterAddressLine2: "Ingresar línea 2 de dirección",
    enterADescription: "Ingresar una descripción",
    enterAnnouncementBody: "Ingresar cuerpo del anuncio",
    enterAnnouncementTitle: "Ingresar título del anuncio",
    enterChangeLog: "Ingresar registro de cambios",
    enterCitySlashTown: "Ingresar ciudad",
    enterContentDescription: "Ingresar descripción del contenido",
    enterContentName: "Ingresar nombre del contenido",
    enterContractNumber: "Ingresar número de contrato",
    enterCourseDescription: "Ingresar descripción del curso",
    enterCourseName: "Ingresar nombre del curso",
    enterCreditHours: "Ingresar horas de crédito",
    enterDayName: "Ingresar nombre del día",
    enterDescribeYourChanges: "Ingresar Describir sus cambios",
    enterEmail: "Ingresar correo electrónico",
    enterEnrollmentLimit: "Ingresar límite de inscripción",
    enterEventEmailAddress: "Ingresar direcciones de correo electrónico del evento",
    enterEventName: "Ingresar nombre del evento",
    enterEventPhoneNumber: "Ingresar número de teléfono del evento",
    enterFirstName: "Ingresar nombre de pila",
    enterLastName: "Ingresar apellido",
    enterLocationName: "Ingresar nombre de la ubicación",
    enterMaximumRegistrationLimit: "Ingresar límite máximo de registro",
    enterMeetingUrl: "Ingresar URL de la reunión",
    enterName: "Ingresar nombre",
    enterNfpaContactEmail: "Ingresar correo electrónico de contacto de la NFPA",
    enterNfpaContactName: "Ingresar nombre del contacto de la NFPA",
    enterNfpaContactPhone: "Ingresar teléfono de contacto de la NFPA",
    enterNfpaID: "Ingresar identificación de la NFPA",
    enterOrganizationName: "Ingresar nombre de la organización",
    enterPackageName: "Ingresar nombre del paquete",
    enterProductDescription: "Ingresar descripción del producto",
    enterProductName: "Ingresar nombre del producto",
    enterRegistrationURL: "Ingresar URL de registro",
    enterResponse: "Ingresar respuesta",
    enterUnitDescription: "Ingresar descripción de la unidad",
    enterUnitName: "Ingresar nombre de la unidad",
    enterYourEmailAddress: "Ingrese su dirección de correo electrónico…",
    enterYourFeedback: "Ingresar sus comentarios",
    enterYourFirstName: "Ingrese su nombre de pila…",
    enterYourLastName: "Ingrese su apellido…",
    enterYourPhoneNumber: "Ingrese su número de teléfono…",
    enterZipCodePostalCode: "Ingresar código postal",
    enterZipcodeSlashPostalCode: "Ingresar código postal",
    error: "Error",
    errorDeletingFile: "Error al eliminar el archivo",
    errorFetchingArchivedAnnouncements: "Error al extraer anuncios archivados",
    errorMessageColon: "Mensaje de error:",
    errorWhenFetchingUnits: "Error al extraer unidades.",
    evaluation: "Evaluación",
    evaluationReport: "Informe de evaluación",
    evaluationSubmittedSuccessfully: "Evaluación enviada correctamente",
    event: "Evento",
    eventCalendar: "Calendario de eventos",
    eventCEUS: "CEU de eventos",
    eventContact: "Contacto del evento",
    eventContactInformation: "Información de contacto del evento",
    eventdaygetcredithoursdescriptionEventDayGetCEUsCEUs:
        "CEU de {{eventDayGetCreditHoursDescription}} | {{eventDayGetCEUs}}",
    eventDayName: "Nombre del día del evento",
    eventDaysCannotOccurOnTheSameDay: "Los días del evento no pueden ocurrir el mismo día.",
    eventDeletedSuccessfully: "Evento eliminado correctamente",
    eventDetails: "Detalles del evento",
    eventEnrollments: "Inscripciones a eventos",
    eventID: "Identificación del evento",
    eventInformation: "Información del evento",
    eventName: "Nombre del evento",
    eventPhoneNumber: "Número de teléfono del evento",
    events: "Eventos",
    eventType: "Tipo de evento",
    exceededEnrollmentLimit: "Límite de inscripciones excedido",
    exceptionGranted: "Excepción concedida",
    exceptionShouldOnlyBeGrantedBasedOnAValidExceptionReasonORAfterSpeakingWithTheRequestorToFullyUnderstandTheNeedForExtraHours:
        "La excepción solo debería otorgarse en función de un motivo de excepción válido O después de hablar con el solicitante para entender por completo la necesidad de horas adicionales. ",
    expiration: "Vencimiento",
    expirationDate: "Fecha de vencimiento",
    expirationExtendedSuccessfully: "Vencimiento extendido correctamente.",
    expirationExtensionFailed: "Error al extender el vencimiento.",
    expired: "Vencido",
    failed: "Se produjo un error.",
    failedToActivateCourse: "No se pudo activar el curso.",
    failedToActivateProduct: "No se pudo activar el producto.",
    failedToArchiveAnnouncement: "No se pudo archivar el anuncio.",
    failedToArchiveCourse: "No se pudo archivar el curso.",
    failedToArchiveProduct: "No se pudo archivar el producto.",
    failedToCreateANewEnrollment: "No se pudo crear una nueva inscripción.",
    failedToDeactivateCourse: "No se pudo desactivar el curso.",
    failedToDeactivateProduct: "No se pudo desactivar el producto.",
    failedToEnroll: "No se pudo realizar la inscripción.",
    failedToGetProductsCount: "No se pudo obtener el recuento de productos.",
    failedToLinkSCORMPackageToProduct: "No se pudo vincular el paquete SCORM al producto.",
    failedToRetrieveProductScormPackages:
        "No se pudieron recuperar los paquetes SCORM de productos.",
    failedToSaveAttempt: "No se pudo guardar el intento.",
    failedToUpdateUser: "No se pudo actualizar el usuario.",
    feedback: "Comentarios",
    fieldsChanged: "Campos cambiados",
    fileMayContainAHeaderRow: "El archivo puede contener una fila de encabezado.",
    fileMustBeACSV: "El archivo debe ser un CSV.",
    fileName: "Nombre del archivo",
    fileNameColumn: "Columna del nombre del archivo",
    fileUpload: "Carga de archivo",
    filterByEventType: "Filtrar por tipo de evento",
    filterByLanguage: "Filtrar por idioma",
    filterByOrganization: "Filtrar por organización",
    filterByProduct: "Filtrar por producto",
    filterByProvider: "Filtrar por proveedor",
    filterByRole: "Filtrar por función",
    filterBySalesType: "Filtrar por tipo de venta",
    filterByStatus: "Filtrar por estado",
    filterByTeachingMethods: "Filtrar por métodos de enseñanza",
    filterByTopic: "Filtrar por tema",
    filterByTravelPreferences: "Filtrar por preferencias de viaje",
    filterResults: "Filtrar resultados",
    firstName: "Nombre de pila",
    firstNameIsRequired: "Se requiere el nombre de pila.",
    florida: "Florida",
    forAnyQuestionsPleaseContactNFPASupport:
        "Si tiene alguna pregunta, comuníquese con el soporte de la NFPA.",
    forInvoiceStatus: "Para el estado de la factura...",
    forQuestionsAboutTheEventContact: "Si tiene preguntas sobre el evento, comuníquese con:",
    forQuestionsAboutTheLMSContact: "Si tiene preguntas sobre el LMS, comuníquese con:",
    forQuestionsAboutTheTrainingContact:
        "Si tiene preguntas sobre la capacitación, comuníquese con:",
    frequentlyAskedQuestions: "Preguntas frecuentes",
    fridayAbbreviated: "Vie",
    generalInformation: "Información general",
    generating: "Generando",
    georgia: "Georgia",
    getStarted: "Empezar",
    getStartedLearningPathButton: "Botón para empezar ruta de aprendizaje",
    getStartedSeriesButton: "Botón de serie para empezar",
    goToCredly: "Ir a Credly",
    goToEvent: "Ir al Evento",
    goToEvents: "Ir a Eventos",
    goToReports: "Ir a Informes",
    goToSupport: "Ir a Soporte",
    grantException: "Conceder excepción",
    haveFeedback: "¿Tiene comentarios?",
    haveYouConfirmedThisInstructorsAvailability:
        "¿Ha confirmado la disponibilidad de este instructor?",
    hawaii: "Hawái",
    hideContactInformation: "Ocultar información de contacto",
    highScore: "Puntuación alta",
    host: "Host",
    hostTimeZone: "Zona horaria del host",
    hours: "Horas",
    howItWorks: "Cómo funciona",
    howLongIsTheCertificateValidForInYears:
        "¿Por cuánto tiempo es válido el certificado (en años)?",
    iAmAbleToTravelDomestically: "Puedo viajar dentro del país.",
    iAmAbleToTravelInternationally: "Puedo viajar fuera del país.",
    iAmAvailableToTeachInPerson: "Estoy disponible para enseñar de forma presencial.",
    iAmAvailableToTeachVirtually: "Estoy disponible para enseñar de forma virtual.",
    iConfirmTheExceptionReasonIsValidAndorThatIHaveSpokenWithTheRequestorAndApproveThisException:
        "Confirmo que el motivo de la excepción es válido y que he hablado con el solicitante y apruebo esta excepción.",
    id: "Identificación",
    idaho: "Idaho",
    ids: "Identificaciones",
    ifALearnerRequiresAdditionalTimeToCompleteTheTrainingTheExpirationDateMayBeExtended:
        "Si un alumno requiere tiempo adicional para completar el entrenamiento, la fecha de vencimiento puede extenderse.",
    ifYouBelieveThisIsAnErrorPleaseContact: "Si cree que se trata de un error, comuníquese con",
    iHaveConfirmedThisInstructorsAvailability:
        "He confirmado la disponibilidad de este instructor*.",
    illinois: "Illinois",
    importCSV: "Importar CSV",
    importEnrollmentCSV: "Importar CSV de inscripción",

    importStatus: "Estado de importación",
    inactive: "Inactivo",
    includeArchived: "Incluir Archivado",
    includedInThisUnit: "Incluido en esta unidad",
    incomplete: "Incompleto",
    incorrect: "Incorrecto",
    indiana: "Indiana",
    inHyphenProgress: "En curso",
    inOrderToArchiveThisCourseYouNeedTo: "Para archivar este curso, tiene que hacer lo siguiente:",
    inOrderToArchiveThisProductYouNeedTo:
        "Para archivar este producto, tiene que hacer lo siguiente:",
    inOrderToDeactivateThisCourseYouNeedTo:
        "Para desactivar este curso, tiene que hacer lo siguiente:",
    inPerson: "Presencial",
    inPersonEvent: "Evento presencial",
    inPersonTraining: "Entrenamiento presencial",
    inProgress: "En curso",
    inprogressLearners: "Alumnos en curso",
    inProgressLearners: "Alumnos en curso",
    inputTheEventClassNameTheClassNameWillNotBeSeen:
        "Ingrese el nombre de la clase de evento. Los alumnos no verán el nombre de la clase y se usará únicamente con fines organizativos internos.",
    instructor: "Instructor",
    instructorAverageRating: "Certificación promedio del instructor",
    instructorInfo: "Información del instructor",
    instructorInformation: "Información del instructor",
    instructorLed: "Impartido por un instructor",
    instructorLedEventsContracts: "Contratos de eventos impartidos por un instructor",
    instructorLedTraining: "Guiada Por Un Instructor",
    instructorLedTrainingEvent: "Evento impartido por un instructor",
    instructorName: "Nombre del instructor",
    instructorProfile: "Perfil del instructor",
    instructorRoleCannotBeRemovedDueToIncompleteEventAssignments:
        "La función de instructor no puede eliminarse debido a asignaciones de eventos incompletas.",
    international: "Internacional",
    inThisTimePeriod: "En este período…",
    inverted: "Invertido",
    invitePending: "Invitación pendiente",
    iowa: "Iowa",
    issueChangingPreferredLanguage: "Hubo un problema al cambiar su idioma preferido.",
    iWantToRunATrainingStatisticsReportFor:
        "Quiero ejecutar un informe de estadísticas de capacitación para...",
    iWantToRunAn: "Quiero ejecutar un(a)...",
    iWantToRunAnAllTrainingReportBasedOn:
        "Quiero ejecutar un informe de Todo el entrenamiento basado en...",
    iWantToRunAnEvaluationReportBasedOn: "Quiero ejecutar un informe de Evaluación basado en...",
    iWantToRunAReport: "Quiero ejecutar un informe sobre...",
    iWantToRunATrainingMaterialsOutlineBasedOn:
        "Quiero ejecutar un Esquema de materiales de entrenamiento basado en...",
    iWantToRunATranscriptReportFor: "Quiero ejecutar un informe de Expediente para...",
    kansas: "Kansas",
    kentucky: "Kentucky",
    language: "Idioma",
    languageBarrierIsAnExampleWhereTrainingMayExtend:
        "La barrera del idioma es un ejemplo en el que el entrenamiento puede extenderse más allá de las horas de contacto recomendadas (CEU) (es decir, el idioma del entrenamiento y de los materiales no es el idioma principal de los participantes).",
    languageBarriers: "Barreras del idioma",
    languageSelect: "Seleccione el idioma que le gustaría usar con el LMS.",
    languageSelection: "Selección de idioma",
    languageUpdatedSuccessfully: "Idioma actualizado correctamente.",
    lastChange: "Último cambio",
    lastChanged: "Cambiado por última vez",
    lastName: "Apellido",
    lastNameIsRequired: "Se requiere el apellido.",
    launchAssessment: "Iniciar evaluación",
    launchCourse: "Iniciar curso",
    launchTraining: "Iniciar entrenamiento",
    learner: "Alumno",
    learnerAssessment: "Evaluación del alumno",
    learnerBreakdown: "Desglose del alumno",
    learnerName: "Nombre del alumno",
    learnerRegisteredSuccessfully: "El alumno se registró correctamente.",
    learnersInThisGroupMatchedRecordsForLearnersAlreadyOnTheEnrollmentListForTheTraining:
        "Los alumnos de este grupo coincidían con los registros de los alumnos que ya estaban en la lista de inscripción del entrenamiento.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListDueToExceedingTheRegistrationLimitForThisTraining:
        "Los alumnos de este grupo no se agregaron a la lista de inscripción debido a que se superó el límite de inscripciones para este entrenamiento.",
    learnersInThisGroupWereNotAddedToTheEnrollmentListForThisTrainingDueToAPotentialDataError:
        "Los alumnos de este grupo no se agregaron a la lista de inscripción para este entrenamiento debido a un posible error en los datos.",
    learnersInThisGroupWereSuccessfullyAddedToTheEnrollmentListForTheTraining:
        "Los alumnos de este grupo se agregaron correctamente a la lista de inscripción para el entrenamiento.",
    learnersWhoHaveCompletedThePreviousVersionWillRemainOnThatVersion:
        "Los alumnos que hayan completado la versión anterior permanecerán en esa versión.",
    learnerTranscriptReport: "Informe de expediente del alumno",
    learning: "Aprendizaje",
    learningPath: "Ruta de aprendizaje",
    letUsKnowHowWeCanHelp: "Háganos saber cómo podemos ayudarle.",
    licensee: "Licenciatario",
    linkingCourseToAUnitHasFailed: "Ha fallado la vinculación del curso a una unidad.",
    linkingSCORMPackageToProductFailed: "No se pudo vincular el paquete SCORM al producto.",
    listAndLast: "{{list}} y {{last}}",
    live: "En vivo",
    liveVirtual: "Virtual en vivo",
    liveVirtualEvent: "Evento virtual en vivo",
    liveVirtualTraining: "Entrenamiento virtual en vivo",
    lmsID: "Identificación del LMS",
    lmsId: "Identificación del LMS",
    loadingEmulationSession: "La sesión de emulación está cargando.",
    location: "Ubicación",
    locationName: "Nombre de la ubicación",
    locationNameIsRequired: "Se requiere el nombre de la ubicación.",
    logIn: "Iniciar sesión",
    logOut: "Cerrar sesión",
    louisiana: "Luisiana",
    mainContent: "Contenido principal",
    maine: "Maine",
    manageYourProfile: "Administre su perfil en www.nfpa.org.",
    markComplete: "Marcar como Completo",
    markingNotificationAsReadFailed: "Error al marcar la notificación como leída.",
    markPassed: "Marcar como Aprobado",
    maryland: "Maryland",
    massachusetts: "Massachusetts",
    maximumRegistration: "Registro máximo",
    maximumRegistrationLimit: "Límite de registro máximo",
    maxScore: "Puntuación máxima",
    meetingUrl: "URL de la reunión",
    memberOf: "Miembro de",
    menu: "Menú",
    menuColumn: "Columna del menú",
    messageByUpdatedByGetFirstAndLastName: "{{message}} por {{updatedByGetFirstAndLastName}}",
    michigan: "Míchigan",
    minnesota: "Minnesota",
    mississippi: "Misisipi",
    missouri: "Misuri",
    mondayAbbreviated: "Lun",
    montana: "Montana",
    moveAssessmentProductAssessmentScormPackageNameDown:
        "Mover evaluación {{productAssessmentScormPackageName}} hacia abajo",
    moveAssessmentProductAssessmentScormPackageNameUp:
        "Mover evaluación {{productAssessmentScormPackageName}} hacia arriba",
    moveContentCourseContentContentNameDown:
        "Mover contenido {{courseContentContentName}} hacia abajo",
    moveContentCourseContentContentNameUp:
        "Mover contenido {{courseContentContentName}} hacia arriba",
    moveContentPcContentNameDown: "Mover contenido {{pcContentName}} hacia abajo",
    moveContentPcContentNameUp: "Mover contenido {{pcContentName}} hacia arriba",
    moveContentProductContentContentNameDown:
        "Mover contenido {{productContentContentName}} hacia abajo",
    moveContentProductContentContentNameUp:
        "Mover contenido {{productContentContentName}} hacia arriba",
    moveCourseUnitCourseCourseNameDown: "Mover curso {{unitCourseCourseName}} hacia abajo",
    moveCourseUnitCourseCourseNameUp: "Mover curso {{unitCourseCourseName}} hacia arriba",
    moveUnitProductUnitNameDown: "Mover unidad {{productUnitName}} hacia abajo",
    moveUnitProductUnitNameUp: "Mover unidad {{productUnitName}} hacia arriba",
    myLearning: "Mi aprendizaje",
    myProfile: "Mi perfil",
    myTeachingAssignments: "Mis asignaciones de enseñanza",
    name: "Nombre",
    nameColumn: "Columna del nombre",
    nationalFireProtectionAssociation:
        "Asociación Nacional de Protección contra Incendios (National Fire Protection Association)",
    nationalFireProtectionAssociationCatalog:
        "Catálogo de la Asociación Nacional de Protección contra Incendios (National Fire Protection Association)",
    nebraska: "Nebraska",
    needFurtherAssistance: "¿Necesita ayuda adicional?",
    needToAddOrRemoveTeamMembers: "¿Necesita agregar o eliminar a miembros del equipo?",
    neutral: "Neutro",
    nevada: "Nevada",
    new: "Nuevo",
    newAnnouncement: "Nuevo anuncio",
    newContractOrganization: "Nueva organización del contrato",
    newCourse: "Nuevo curso",
    newENProvider: "Nuevo proveedor AEN",
    newEnrollments: "Nuevas inscripciones",
    newExpirationDate: "Nueva fecha de vencimiento",
    newHampshire: "New Hampshire",
    newJersey: "Nueva Jersey",
    newMexico: "Nuevo México",
    newOnlineLearningContract: "Nuevo contrato de aprendizaje en línea",
    newUser: "Nuevo usuario",
    newVersionVersionNumber: "Nueva versión {{versionNumber}}",
    newYork: "Nueva York",
    nextMonth: "Mes siguiente",
    nextPage: "Página siguiente",
    nfpa: "NFPA",
    nfpaAdmin: "Admin de la NFPA",
    nfpaAdminEmail: "Correo electrónico del Admin de la NFPA",
    nfpaAdminName: "Nombre del Admin de la NFPA",
    nfpaAdminPhone: "Teléfono del Admin de la NFPA",
    nfpaCatalog: "Catálogo de la NFPA",
    nfpaCatalogEvent: "Evento del catálogo de la NFPA",
    nfpaContact: "Contacto de la NFPA",
    nfpaContactEmail: "Correo electrónico de contacto de la NFPA",
    nfpaContactName: "Nombre de contacto de la NFPA",
    nfpaContactPhone: "Teléfono de contacto de la NFPA",
    nfpaCustomerSupport: "Atención al cliente de la NFPA",
    nfpaEvent: "Evento de la NFPA",
    nfpaId: "Identificación de la NFPA",
    nfpaIsCommittedToProvidingYouWithHighqualityContentToHelpMeetYourLearningNeedsWeAppreciateYourFeedbackYourAnswersAndCommentsWillProvideValuableInputAsWeContinueToEnhanceOurOfferings:
        "La NFPA se compromete a brindarle contenido de alta calidad para ayudarle a satisfacer sus necesidades de aprendizaje. Agradecemos sus comentarios. Sus respuestas y comentarios brindarán aportes valiosos a medida que continuamos mejorando nuestras ofertas.",
    nfpaOperations: "Operaciones de la NFPA",
    nfpaSupport: "Soporte de la NFPA",
    no: "No",
    noAssignmentsForThisTraining: "No hay asignaciones para este entrenamiento.",
    noAttemptDataForTheSelectedAttempt: "No hay datos de intento para el intento seleccionado.",
    noChangesWereMadeToThisSection: "No se realizaron cambios en esta sección.",
    noCoursesAdded: "No se agregaron cursos.",
    noEnrollmentLimit: "NO HAY UN LÍMITE DE INSCRIPCIONES.",
    noEvaluationAdded: "No se agregó ninguna evaluación.",
    noHostAssigned: "No se ha asignado un host",
    noInstructorAssigned: "No se asignó ningún instructor.",
    noInstructorsCouldBeFoundForThisProduct:
        "No se pudieron encontrar instructores para este producto.",
    noKeepEditing: "No, siga editando.",
    noLimitSetInvoiceWillBeIssuedFollowingTheEvent:
        "No se ha establecido un límite. La factura se emitirá después del evento.",
    noLocationAdded: "No se agregó ninguna ubicación.",
    noOrganizationSelected: "No se seleccionó ninguna organización.",
    noOrganizationsFound: "No se encontraron organizaciones.",
    noProductAssigned: "No se asignó ningún producto.",
    noProductSelected: "No se seleccionó ningún producto.",
    noProviderSelected: "No se seleccionó ningún proveedor.",
    noProvidersFound: "No se encontraron proveedores.",
    noResultsFoundPleaseTryAgain: "No se encontraron resultados. Inténtelo de nuevo.",
    northCarolina: "Carolina del Norte",
    northDakota: "Dakota del Norte",
    noScheduleCreated: "No se creó ningún cronograma.",
    notApplicableShort: "N/C",
    notAValidCultureCode: "No es un código cultural válido.",
    noteAnInvitePendingLearnerCountsTowardsTheTotalEnrollmentNumberForTheTraining:
        'Nota: un alumno con una "Invitación pendiente"cuenta para el número total de inscripciones del entrenamiento".',
    noteBreakTimeIsNOTToBeIncludedInAdjustedHoursCEUs:
        "Nota: el tiempo de descanso NO debe incluirse en las horas ajustadas/CEU.",
    noteTheExpirationHistoryLogWillTrackEachTimeTheExpirationDateIsUpdated:
        "Nota: el registro del historial de vencimientos realizará un seguimiento cada vez que se actualice la fecha de vencimiento.",
    noteYouDoNotNeedToIncludeTheEventType:
        "Nota: no es necesario incluir el tipo de evento (presencial o virtual en vivo) en el nombre de la clase, ya que esto se indicará en la selección del tipo de evento.",
    notFound: "No existe.",
    noTrainingsFound: "No se encontraron entrenamientos.",
    notStarted: "No iniciado",
    noUnitsAdded: "No se agregaron unidades.",
    noZipPackageAdded: "No se agregó ningún paquete zip.",
    number: "Número",
    ohio: "Ohio",
    oklahoma: "Oklahoma",
    oneVeryUnlikelyOnAScaleOfOneToTen: 'Uno es "Muy improbable" en una escala del uno al diez',
    online: "En línea",
    onlineLearning: "Aprendizaje en línea",
    onlineLearningContracts: "Contratos de aprendizaje en línea",
    onlineLearningProductEnrollments: "Inscripciones de productos de aprendizaje en línea",
    onlineLearningTraining: "Entrenamiento de aprendizaje en línea",
    onlyAvailableForNFPAOLLTrainingProducts:
        "Solo disponible para productos de entrenamiento OLL de la NFPA",
    optional: "Opcional",
    order: "Orden",
    oregon: "Oregón",
    organization: "Organización",
    organizationDefaultEventContact: "Contacto del evento predeterminado de la organización",
    organizationName: "Nombre de la organización",
    organizations: "Organizaciones",
    organizationsNeedToBeAddedBeforeTheyCanBeAssignedToAUser:
        "Es necesario agregar las organizaciones antes de poder asignarlas a un usuario.",
    organizationsNeedToBeAddedBeforeTheyCanBeAssociatedToAUser:
        "Es necesario agregar las organizaciones antes de poder asociarlas a un usuario.",
    organizationStatus: "Estado de la organización",
    organizedBy: "Organizado por",
    original: "Original",
    OrSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        " O simplemente asegúrese de que su archivo cumpla con estos requisitos antes de cargarlo:",
    orSimplyMakeSureYourFileMeetsTheseRequirementsBeforeUploading:
        "O simplemente asegúrese de que su archivo cumpla con estos requisitos antes de cargarlo:",
    other: "Otro",
    overview: "Descripción general",
    paginationNavigationNext: "Navegación de paginación Siguiente",
    paginationNavigationPrevious: "Navegación de paginación Anterior",
    participants: "Participantes",
    participantsRequireAdditionalLearningTimeBasedOnInitialKnowledgeLevel:
        "Los participantes requieren tiempo de aprendizaje adicional según su nivel de conocimiento inicial.",
    participantSummary: "Resumen del participante",
    passed: "Aprobado",
    password: "Contraseña",
    pastAnnouncementsWillDisplayHereOnceTheyHaveBeenArchived:
        "Los anuncios anteriores se mostrarán aquí una vez que se hayan archivado.",
    pastTrainings: "Entrenamientos pasados",
    payloadCannotBeNullOrUndefined: "La carga útil no puede ser nula o indefinida.",
    pending: "Pendiente",
    pennsylvania: "Pensilvania",
    phone: "Teléfono",
    phoneNumber: "Número de teléfono",
    physicalMaterials: "Materiales físicos",
    please: "Por favor",
    pleaseContactTheHostForStartAndEndTimes:
        "Comuníquese con el organizador para conocer los horarios de inicio y finalización.",
    pleaseEnterAValidEmailAddress: "Ingrese una dirección de correo electrónico válida.",
    pleaseEnterAValidPhoneNumber: "Ingrese un número de teléfono válido.",
    pleaseReviewBeforeReplacingYourENProvider: "Revise antes de reemplazar a su proveedor AEN.",
    pleaseReviewBeforeReplacingYourOrganization: "Revise antes de reemplazar a su organización.",
    pleaseReviewBeforeReplacingYourProduct: "Revise antes de reemplazar su producto.",
    pleaseReviewTheSummaryEvaluationResultsFromParticipantsAsItProvidesKeyInsightsAboutTheirTrainingExperienceResultsIncludeFeedbackConcerningTheInstructorTrainingContentAndTheLearningEnvironment:
        "Revise el resumen de los resultados de la evaluación de los participantes, ya que proporciona información clave sobre su experiencia de entrenamiento. Los resultados incluyen comentarios sobre el instructor, el contenido del entrenamiento y el entorno de aprendizaje.",
    pleaseSelectAllThatApply: "Seleccione todas las opciones que correspondan.",
    pleaseSelectTheDateAndTimesOfTheEventScheduleDONOTIncludeBreaksIncludingLunchInTheStartAndEndTimesAsTheseTimesAreConsideredNoncontactHoursAndAreNotToBeCountedTowardsContinuingEducationCreditsCEUsForExampleAHourOnedayCourseCouldBeSetUpAs:
        "Seleccione la fecha y los horarios del cronograma del evento. NO incluya descansos (incluido el almuerzo) en las horas de inicio y finalización, ya que estos tiempos se consideran horas sin contacto y no cuentan para los créditos de educación continua (CEU). Por ejemplo, un curso de un día de 7 horas podría configurarse como:",
    preferredLanguage: "Idioma preferido",
    preview: "Vista previa",
    previewAssessment: "Evaluación previa",
    previewEvaluation: "Evaluación previa",
    previousMonth: "Mes anterior",
    previousPage: "Página anterior",
    problemLoadingAssociatedProducts: "Hubo un problema al cargar la lista de productos asociados.",
    problemLoadingAssociatedProductsList:
        "Hubo un problema al cargar la lista de productos asociados.",
    problemLoadingOrganizations: "Hubo un problema al cargar la lista de organizaciones.",
    problemLoadingProducts: "Hubo un problema al cargar los productos.",
    problemLoadingProviders: "Hubo un problema al cargar la lista de proveedores.",
    processing: "Procesando",
    product: "Producto",
    productAudience: "Audiencia del producto",
    productDescription: "Descripción del producto",
    productDetails: "Detalles del producto",
    productID: "Identificación del producto",
    productId: "Identificación del producto",
    productIDAndFileAreNeeded: "Se necesitan la identificación del producto y el archivo.",
    productIdIsAutomaticallyGeneratedAndNotEditable:
        "La identificación del producto se genera automáticamente y no se puede editar.",
    productInfo: "Información del producto",
    productInformation: "Información del producto",
    productIsNowActive: "El producto ya está activo.",
    productIsNowArchived: "El producto se ha archivado.",
    productIsNowDeactivated: "El producto ya está desactivado.",
    productName: "Nombre del producto",
    products: "Productos",
    productType: "Tipo de producto",
    productTypeSelection: "Selección del tipo de producto",
    profile: "Perfil",
    profileUpdateFailed: "Error al actualizar el perfil",
    profileUpdateFailedAddingLanguage: "La actualización del perfil no pudo agregar el idioma.",
    profileUpdateFailedAddingTeachingMethod:
        "La actualización del perfil no pudo agregar el método de enseñanza.",
    profileUpdateFailedAddingTravelMethod:
        "La actualización del perfil no pudo agregar el método de viaje.",
    profileUpdateFailedRemovingLanguage: "La actualización del perfil no pudo eliminar el idioma.",
    profileUpdateFailedRemovingTeachingMethod:
        "La actualización del perfil no pudo eliminar el método de enseñanza.",
    profileUpdateFailedRemovingTravelMethod:
        "La actualización del perfil no pudo eliminar el método de viaje.",
    progress: "Progreso",
    propstextNotSelected: "{{propsText}} no seleccionado",
    propstextSelected: "{{propsText}} seleccionado",
    provider: "Proveedor",
    providerName: "Nombre del proveedor",
    publish: "Publicar",
    publishCourse: "Publicar curso",
    published: "Publicado",
    publishEvent: "Publicar evento",
    publishingSummary: "Resumen de la publicación",
    question: "Pregunta",
    questionBreakdown: "Desglose de preguntas",
    quickLinks: "Enlaces rápidos",
    register: "Registrar",
    registration: "Registro",
    registrationInformation: "Información de registro",
    registrationInformationDoesNotApplyForContractEventsToViewMoreInformationRegardingTheContractVisitTheEventDetailsTab:
        "La información de registro no se aplica a Eventos de contrato. Para ver más información sobre el contrato, visite la pestaña Detalles del evento.",
    registrationLink: "Enlace de registro",
    registrationURL: "URL de registro",
    remove: "Quitar",
    removeCourseFromAnyProducts: "Eliminar curso de cualquier producto",
    removeInstructor: "Eliminar instructor",
    replace: "Reemplazar",
    replaceAnENProvider: "Reemplazar un proveedor AEN",
    replaceAnOrganization: "Reemplazar una organización",
    replaceContent: "Reemplazar contenido",
    replaceENProvider: "Reemplazar proveedor AEN",
    replaceOrganization: "Reemplazar organización",
    replacePackage: "Reemplazar paquete",
    replaceProduct: "Reemplazar producto",
    reportDownloads: "Descargas del informe",
    reportNameReport: "{{reportName}}",
    reports: "Informes",
    reportType: "Tipo de informe",
    required: "Requerido",
    requiredTrainings: "Capacitaciones requeridas",
    reset: "Restablecer",
    resetALL: "RESTABLECER TODO",
    resetForAllLanguages: "Restablecer para Todos los idiomas",
    resources: "Recursos",
    response: "Respuesta",
    result: "Resultado",
    resumeTraining: "Reanudar el entrenamiento",
    returnToHomepage: "Volver a la página de inicio",
    reviewAssessmentResults: "Revisar los resultados de la evaluación",
    reviewEvaluationResults: "Revisar los resultados de la evaluación",
    reviewTraining: "Revisar entrenamiento",
    reviewTrainingContent: "Revisar el contenido del entrenamiento",
    rhodeIsland: "Rhode Island",
    role: "Función",
    roles: "Funciones",
    runAReport: "Ejecutar un informe",
    running: "Ejecutándose",
    runningLMSAppVersionProcessEnvREACTAPPBUILDVERSION:
        "Versión de la aplicación LMS en ejecución: {{processEnvREACTAPPBUILDVERSION}}",
    runReport: "Ejecutar informe",
    salesType: "Tipo de ventas",
    saturdayAbbreviated: "Sáb",
    save: "Guardar",
    saveAndExit: "Guardar y salir",
    saveChanges: "Guardar cambios",
    saveContent: "Guardar contenido",
    saveCourseDetailsChanges: "Guardar cambios en los detalles del curso",
    saveExpirationDate: "Guardar fecha de vencimiento",
    savePackage: "Guardar paquete",
    savingAnAttemptMayResultInAnUpdateToTheLearnersProgress:
        "Guardar un intento puede resultar en una actualización del progreso del alumno.",
    schedule: "Programar",
    scheduleSummary: "Resumen del cronograma",
    score: "Puntuación",
    search: "Buscar",
    searchByBadgeName: "Buscar por nombre de insignia",
    searchByEmailNameOrId: "Buscar por correo electrónico, nombre o identificación",
    searchByEventNameOrId: "Buscar por nombre o identificación del evento",
    searchByName: "Buscar por nombre",
    searchByNameOrId: "Buscar por nombre o identificación",
    searchByNameOrLocation: "Buscar por nombre o ubicación",
    searchByOrganizationName: "Buscar por nombre de la organización",
    searchByProviderName: "Buscar por nombre del proveedor",
    searchOrFilterToSeeResults: "Buscar o filtrar para ver los resultados",
    select: "Seleccionar",
    selectABadgeToAssociateWithThisProduct:
        "Seleccione una insignia para asociar con este producto.",
    selectADate: "Seleccionar una fecha",
    selectADateRange: "Seleccionar un rango de fechas",
    selectADayThatContainsAnInstructorLedTrainingToViewMoreInformation:
        "Seleccione un día que contenga una capacitación guiada por un instructor para ver más información.",
    selectAFile: "Seleccionar un archivo",
    selectALanguage: "Seleccionar un idioma",
    selectAllRolesThatWillApplyToThisUser:
        "Seleccione todas las funciones que se aplicarán a este usuario.",
    selectAnAssociatedProduct: "Seleccionar un producto asociado",
    selectAnENProvider: "Seleccionar un proveedor AEN",
    selectAnIssue: "Seleccionar un problema",
    selectAnOption: "Seleccionar una opción",
    selectAnOrganization: "Seleccionar una organización",
    selectAProduct: "Seleccionar un producto",
    selectATime: "Seleccionar una hora",
    selectATopic: "Seleccionar un tema",
    selectContract: "Seleccionar contrato",
    selectContracts: "Seleccionar contratos",
    selectCourses: "Seleccionar cursos",
    selected: "Seleccionado",
    selectedInstructorWillAppearHere: "El instructor seleccionado aparecerá aquí.",
    selectedProductWillAppearHere: "El producto seleccionado aparecerá aquí.",
    selectENProvider: "Seleccionar proveedor AEN",
    selectEnProvider: "Seleccionar proveedor AEN",
    selectEvents: "Seleccionar eventos",
    selectFile: "Seleccionar archivo",
    selectForAnInPersonEvent: "Seleccionar para un evento presencial",
    selectForAVirtualEvent:
        "Seleccionar para un evento virtual (p. ej., con Microsoft Teams o Zoom)",
    selectInstructor: "Seleccionar instructor",
    selectLearners: "Seleccionar alumnos",
    selectOption: "Seleccionar opción",
    selectOrganization: "Seleccionar organización",
    selectOrganizations: "Seleccionar organizaciones",
    selectProduct: "Seleccionar producto",
    selectProducts: "Seleccionar productos",
    selectTimeZone: "Seleccionar zona horaria",
    selectToViewAnAttempt: "Seleccionar para ver un intento",
    series: "Series",
    session: "Sesión",
    session1: "Sesión 1",
    sessionEndTimeMayNotBePriorToStartTime:
        "La hora de finalización de la sesión puede no ser anterior a la hora de inicio.",
    sessionExpiresAt: "La sesión vence el:",
    sessionIdx: "Sesión {{idx}}",
    sessionPropsSessionNumberToString: "Sesión {{propsSessionNumberToString}}",
    sessionTimesMayNotOverlap: "Los horarios de las sesiones no pueden superponerse.",
    setEmailError: "Ingrese una dirección de correo electrónico válida.",
    shareMyLocationInformation: "Compartir mi información de ubicación",
    shortDateFormat: "DD/MM/YYYY",
    shouldThisCertificateHaveAnExpirationDate:
        "¿Debería este certificado tener fecha de vencimiento?",
    sidebar: "Barra lateral",
    skipToMainContent: "Saltar al contenido principal",
    someLocation: "Alguna ubicación",
    sortOrderColumn: "Columna de orden de clasificación",
    southCarolina: "Carolina del Sur",
    southDakota: "Dakota del Sur",
    spaceAtSpace: " a las ",
    spanish: "Español",
    startDate: "Fecha de inicio",
    startOver: "Empezar de nuevo",
    startTimeAmEndTimeAm: "Hora de inicio: 8:00 a. m. - Hora de finalización: 11:30 a. m.",
    startTimePmEndTimePm: "Hora de inicio: 1:00 p. m. - Hora de finalización: 4:30 p. m.",
    stateIsRequired: "Se requiere el estado.",
    stateSlashProvinceSlashRegion: "Estado/Provincia/Región",
    status: "Estado",
    submit: "Enviar",
    submitAQuestion: "Enviar una pregunta",
    submitEvaluation: "Enviar evaluación",
    submitFeedback: "Enviar comentarios",
    submitted: "Enviado",
    success: "Éxito",
    successfullyEnrolled: "Inscrito correctamente",
    summary: "Resumen",
    sundayAbbreviated: "Dom",
    support: "Soporte",
    switchProfile: "Cambiar perfil",
    syllabus: "Programa de estudios",
    systemInformation: "Información de sistema",
    takeAMomentToReviewYourChangesAndPopulateTheChangeLogBeforeSaving:
        "Tómese un momento para revisar sus cambios y completar el registro de cambios antes de guardarlos.",
    takeAMomentToReviewYourChangesBeforeSavingOnceYouSaveAllEventParticipantsWillBeNotified:
        "Tómese un momento para revisar sus cambios antes de guardarlos. Una vez que lo guarde, se notificará a todos los participantes del evento.",
    takeAMomentToReviewYourCourseBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Tómese un momento para revisar su Curso antes de activarlo. Si desea realizar algún cambio, regrese a la página correspondiente del recorrido.",
    takeAMomentToReviewYourEventBeforePublishingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Tómese un momento para revisar su Evento antes de publicarlo. Si desea realizar algún cambio, regrese a la página correspondiente del recorrido.",
    takeAMomentToReviewYourProductBeforeActivatingIfYouWouldLikeToMakeAnyChangesReturnToTheAppropriatePageOfTheJourney:
        "Tómese un momento para revisar su Producto antes de activarlo. Si desea realizar algún cambio, regrese a la página correspondiente del recorrido.",
    takeAttendanceDateUtilsFormatDatetruecontentItemEventDateToString:
        "Tomar asistencia {{DateUtilsFormatDatetruecontentItemEventDateToString}}",
    takeEvent: "Tomar evento",
    tcredithoursTcreditHourCEU: "{{tcreditHours}} (1 {{tcreditHour}} = 0.1 CEU)",
    teachingCalendar: "Calendario de enseñanza",
    teachingContactInformation: "Información de contacto del docente",
    teachingHistory: "Enseñanza de historia",
    teachingLanguages: "Idiomas de enseñanza",
    teachingMethods: "Métodos de enseñanza",
    teachingPreferences: "Preferencias de enseñanza",
    template: "Plantilla",
    templateSelection: "Selección de plantilla",
    tennessee: "Tennessee",
    tenVeryLikelyOnAScaleOfOneToTen: 'Diez es "Muy probable" en una escala del uno al diez',
    texas: "Texas",
    thatIsDisplayedAsA: "Eso se muestra como...",
    theContentFromThePreviousVersionHasBeenBroughtOverToUseAsAStartingPoint:
        "El contenido de la versión anterior se ha incorporado para utilizarlo como punto de partida.",
    theEvaluationWasNotSubmittedForThisTraining: "No se envió la evaluación de este entrenamiento.",
    theEvaluationWillBecomeAvailableFollowingTheCompletionOfTheSyllabus:
        "La evaluación estará disponible una vez finalizado el plan de estudios.",
    theLastDayOfTheTraining: "El último día del entrenamiento",
    thePointOfContactForThisOrganizationInCaseLearnersHaveQuestions:
        "El punto de contacto de esta organización en caso de que los alumnos tengan preguntas sobre la logística del evento.",
    thePreviewIsNotAvailableYet: "La vista previa aún no está disponible.",
    theRecommendedClassTimeForThisEventIsProductCreditHoursHoursAndShouldNotExceedMaxCreditHoursHours:
        "El tiempo de clase recomendado para este evento es de {{productCreditHours}} horas y no debería superar las {{maxCreditHours}} horas.",
    thereWasAnErrorDeletingEmulationSessionToken:
        "Se produjo un error al eliminar el token de sesión de emulación.",
    thereWasAnErrorEndingEmulationSession:
        "Se produjo un error al finalizar la sesión de emulación.",
    thereWasAnErrorUploadingTheScormPackagePleaseTryAgain:
        "Se produjo un error al cargar el paquete SCORM.  Inténtelo de nuevo.",
    thereWasAnIssueAddingTheCourse: "Hubo un problema al agregar el curso.",
    thereWasAnIssueAddingTheOrganization: "Hubo un problema al agregar la organización.",
    thereWasAnIssueAuthenticating: "Hubo un problema con la autenticación.",
    thereWasAnIssueCalculatingThePercentCompletePleaseRefreshThePageToViewThePercentComplete:
        "Hubo un problema al calcular el porcentaje completado. Actualice la página para ver el porcentaje completado. ",
    thereWasAnIssueChangingYourPreferredLanguage:
        "Hubo un problema al cambiar su idioma preferido.",
    thereWasAnIssueCheckingForDuplicateContractNumbers:
        "Hubo un problema al buscar números de contrato duplicados.",
    thereWasAnIssueCreatingACourse: "Hubo un problema al crear un curso.",
    thereWasAnIssueCreatingAnEvent: "Hubo un problema al crear un evento.",
    thereWasAnIssueCreatingAProduct: "Hubo un problema al crear un producto.",
    thereWasAnIssueCreatingCourseContent: "Hubo un problema al crear contenido del curso.",
    thereWasAnIssueCreatingTheContract: "Hubo un problema al crear el contrato.",
    thereWasAnIssueCreatingTheContractInformation:
        "Hubo un problema al crear la información del contrato.",
    thereWasAnIssueCreatingTheUnit: "Hubo un problema al crear la unidad.",
    thereWasAnIssueCreatingTheUser: "Hubo un problema al crear el usuario.",
    thereWasAnIssueCreatingTheUserRole: "Hubo un problema al crear la función del usuario.",
    thereWasAnIssueDeletingACourse: "Hubo un problema al eliminar un curso.",
    thereWasAnIssueDeletingAUnit: "Hubo un problema al eliminar una unidad.",
    thereWasAnIssueDeletingTheAssessment: "Hubo un problema al eliminar la evaluación.",
    thereWasAnIssueDeletingTheCourseContent: "Hubo un problema al eliminar el contenido del curso.",
    thereWasAnIssueDeletingTheEvent: "Hubo un problema al eliminar el evento.",
    thereWasAnIssueDeletingTheEventDay: "Hubo un problema al eliminar el día del evento.",
    thereWasAnIssueDeletingTheEventSession: "Hubo un problema al eliminar la sesión del evento.",
    thereWasAnIssueDeletingTheProductContent:
        "Hubo un problema al eliminar el contenido del producto.",
    thereWasAnIssueDeletingTheUnit: "Hubo un problema al eliminar la unidad.",
    thereWasAnIssueDeletingTheUnitCourse: "Hubo un problema al eliminar el curso de la unidad.",
    thereWasAnIssueDeletingTheUserRole: "Hubo un problema al eliminar la función del usuario.",
    thereWasAnIssueFetchingCourseProductData:
        "Hubo un problema al extraer los datos del producto del curso.",
    thereWasAnIssueGeneratingAPreviewLink: "Hubo un problema al generar un enlace de vista previa.",
    thereWasAnIssueLaunchingThisCourse: "Hubo un problema al iniciar este curso.",
    thereWasAnIssueLoadingAssessmentResults:
        "Hubo un problema al cargar los resultados de la evaluación.",
    thereWasAnIssueLoadingAssessments: "Hubo un problema al cargar las evaluaciones.",
    thereWasAnIssueLoadingAttempts: "Hubo un problema al cargar los intentos.",
    thereWasAnIssueLoadingAttendance: "Hubo un problema al cargar la asistencia.",
    thereWasAnIssueLoadingContracts: "Hubo un problema al cargar los contratos.",
    thereWasAnIssueLoadingCourseContents: "Hubo un problema al cargar el contenido del curso.",
    thereWasAnIssueLoadingCourseDetails: "Hubo un problema al cargar los detalles del curso.",
    thereWasAnIssueLoadingCourses: "Hubo un problema al cargar los cursos.",
    thereWasAnIssueLoadingEnrollments: "Hubo un problema al cargar las inscripciones.",
    thereWasAnIssueLoadingEvaluationDetails:
        "Hubo un problema al cargar los detalles de la evaluación.",
    thereWasAnIssueLoadingEventContent: "Hubo un problema al cargar el contenido del evento.",
    thereWasAnIssueLoadingEvents: "Hubo un problema al cargar los eventos.",
    thereWasAnIssueLoadingInstructors: "Hubo un problema al cargar a los instructores.",
    thereWasAnIssueLoadingOrganizations: "Hubo un problema al cargar las organizaciones.",
    thereWasAnIssueLoadingProducts: "Hubo un problema al cargar los productos.",
    thereWasAnIssueLoadingProviders: "Hubo un problema al cargar a los proveedores.",
    thereWasAnIssueLoadingQuestionBreakdowns:
        "Hubo un problema al cargar los desgloses de las preguntas.",
    thereWasAnIssueLoadingTheAssessment: "Hubo un problema al cargar la evaluación.",
    thereWasAnIssueLoadingTheBadge: "Hubo un problema al cargar la insignia.",
    thereWasAnIssueLoadingTheBadgeTemplateDetails:
        "Hubo un problema al cargar los detalles de la plantilla de la insignia.",
    thereWasAnIssueLoadingTheBadgeTemplates:
        "Hubo un problema al cargar las plantillas de la insignia.",
    thereWasAnIssueLoadingTheCertificate: "Hubo un problema al cargar el certificado.",
    thereWasAnIssueLoadingTheCourse: "Hubo un problema al cargar el curso.",
    thereWasAnIssueLoadingTheCourseDetails: "Hubo un problema al cargar los detalles del curso.",
    thereWasAnIssueLoadingTheCurrentAnnouncement: "Hubo un problema al cargar el anuncio actual.",
    thereWasAnIssueLoadingTheEnrollment: "Hubo un problema al cargar la inscripción.",
    thereWasAnIssueLoadingTheEvaluation: "Hubo un problema al cargar la evaluación.",
    thereWasAnIssueLoadingTheEvaluationTemplates:
        "Hubo un problema al cargar las plantillas de la evaluación.",
    thereWasAnIssueLoadingTheEvent: "Hubo un problema al cargar el evento.",
    thereWasAnIssueLoadingTheLearnerChecklist:
        "Hubo un problema al cargar la lista de verificación del alumno.",
    thereWasAnIssueLoadingTheProduct: "Hubo un problema al cargar el producto.",
    thereWasAnIssueLoadingTheProductDetails:
        "Hubo un problema al cargar los detalles del producto.",
    thereWasAnIssueLoadingTheRegistration: "Hubo un problema al cargar el registro.",
    thereWasAnIssueLoadingTheReportDownloads:
        "Hubo un problema al cargar las descargas del informe.",
    thereWasAnIssueLoadingTheScheduleAttendance:
        "Hubo un problema al cargar la asistencia del cronograma.",
    thereWasAnIssueLoadingTheSeededUsers:
        "Hubo un problema al cargar a los usuarios inicializados.",
    thereWasAnIssueLoadingTheUserRoles: "Hubo un problema al cargar las funciones de usuario.",
    thereWasAnIssueLoadingTheUsers: "Hubo un problema al cargar a los usuarios.",
    thereWasAnIssueLoadingUnits: "Hubo un problema al cargar las unidades.",
    thereWasAnIssueLoadingUser: "Hubo un problema al cargar el usuario.",
    thereWasAnIssueLoadingUserInfo: "Hubo un problema al cargar la información del usuario.",
    thereWasAnIssueLoadingUserRoles: "Hubo un problema al cargar las funciones de usuario.",
    thereWasAnIssueMarkingThisAssessmentComplete:
        "Hubo un problema al marcar esta evaluación como completa.",
    thereWasAnIssueProductIDWasUndefined:
        "Hubo un problema. La identificación del producto no estaba definida.",
    thereWasAnIssuePublishingTheAnnouncement: "Hubo un problema al publicar el anuncio.",
    thereWasAnIssueRegisteringTheLearner: "Hubo un problema al registrar al alumno.",
    thereWasAnIssueReplacingTheZipPackage: "Hubo un problema al reemplazar el paquete zip.",
    thereWasAnIssueSavingTheProductSelection:
        "Hubo un problema al guardar la selección del producto.",
    thereWasAnIssueSettingTheThisCourseHasNoContentToggle:
        'Hubo un problema al configurar el botón de alternancia "Este curso no tiene contenido".',
    thereWasAnIssueSettingTheThisProductHasNoContentToggle:
        'Hubo un problema al configurar el botón de alternancia "Este producto no tiene contenido".',
    thereWasAnIssueSubmittingTheEvaluation: "Hubo un problema al enviar la evaluación.",
    thereWasAnIssueSwitchingProfiles: "Hubo un problema al cambiar de perfil.",
    thereWasAnIssueTrackingAttendance: "Hubo un problema al seguir la asistencia.",
    thereWasAnIssueUpdatingEnrollments: "Hubo un problema al actualizar las inscripciones.",
    thereWasAnIssueUpdatingSortOrderAfterDeletingUnit:
        "Hubo un problema al actualizar el orden de clasificación después de eliminar la unidad.",
    thereWasAnIssueUpdatingTheAssessment: "Hubo un problema al actualizar la evaluación.",
    thereWasAnIssueUpdatingTheBadgeTemplate:
        "Hubo un problema al actualizar la plantilla de la insignia.",
    thereWasAnIssueUpdatingTheCertifiateExpiration:
        "Hubo un problema al actualizar el vencimiento del certificado.",
    thereWasAnIssueUpdatingTheCEUs: "Hubo un problema al actualizar las CEU.",
    thereWasAnIssueUpdatingTheContent: "Hubo un problema al actualizar el contenido.",
    thereWasAnIssueUpdatingTheContract: "Hubo un problema al actualizar el contrato.",
    thereWasAnIssueUpdatingTheCourse: "Hubo un problema al actualizar el curso.",
    thereWasAnIssueUpdatingTheCourseName: "Hubo un problema al actualizar el nombre del curso.",
    thereWasAnIssueUpdatingTheEvaluationTemplate:
        "Hubo un problema al actualizar la plantilla de la evaluación.",
    thereWasAnIssueUpdatingTheEventDay: "Hubo un problema al actualizar el día del evento.",
    thereWasAnIssueUpdatingTheEventsInstructor:
        "Hubo un problema al actualizar al instructor del evento.",
    thereWasAnIssueUpdatingTheFieldName: "Hubo un problema al actualizar {{fieldName}}.",
    thereWasAnIssueUpdatingTheOrganization: "Hubo un problema al actualizar la organización.",
    thereWasAnIssueUpdatingTheProduct: "Hubo un problema al actualizar el producto.",
    thereWasAnIssueUpdatingTheProductName: "Hubo un problema al actualizar el nombre del producto.",
    thereWasAnIssueUpdatingTheProductWhileStartingOver:
        "Hubo un problema al actualizar el producto al comenzar de nuevo.",
    thereWasAnIssueUpdatingTheScheduleException:
        "Hubo un problema al actualizar la excepción del cronograma.",
    thereWasAnIssueUpdatingTheSortOrder:
        "Hubo un problema al actualizar el orden de clasificación.",
    thereWasAnIssueUpdatingTheUnit: "Hubo un problema al actualizar la unidad.",
    thereWasAnIssueUpdatingTheUnitReceivedAnUnexpectedResult:
        "Hubo un problema al actualizar la unidad. Recibió un resultado inesperado.",
    thereWasAnIssueWithdrawingAnEnrollment: "Hubo un problema al retirar una inscripción.",
    thereWasAProblemCreatingANewEnrollment: "Hubo un problema al crear una nueva inscripción.",
    thereWasAProblemCreatingTheReportDownload: "Hubo un problema al crear la descarga del informe.",
    thereWasAProblemGettingAvailableReportDownloads:
        "Hubo un problema al obtener descargas de informes.",
    thereWasAProblemGettingAvailableReports: "Hubo un problema al obtener informes.",
    thereWasAProblemGettingProductAssessments:
        "Hubo un problema al obtener evaluaciones de productos.",
    thereWasAProblemGettingRegistration: "Hubo un problema al obtener el registro.",
    thereWasAProblemLoadingEnrollments: "Hubo un problema al cargar las inscripciones.",
    thereWasAProblemLoadingTheAssociatedProductsList:
        "Hubo un problema al cargar la lista de productos asociados.",
    thereWasAProblemLoadingTheContract: "Hubo un problema al cargar el contrato.",
    thereWasAProblemLoadingTheEvent: "Hubo un problema al cargar el evento.",
    thereWasAProblemLoadingTheOrganization: "Hubo un problema al cargar la organización.",
    thereWasAProblemLoadingTheProfile: "Hubo un problema al cargar el perfil.",
    thereWasAProblemLoadingTheReportDownloads: "Hubo un problema al cargar descargas de informes.",
    thereWasAProblemRemovingACourseFromTheUnitWhileStartingOver:
        "Hubo un problema al eliminar un curso de la unidad al comenzar de nuevo.",
    thereWasAProblemReturningBadgeTemplates:
        "Hubo un problema al devolver las plantillas de insignias.",
    thereWasAProblemReturningContracts: "Hubo un problema al devolver los contratos.",
    thereWasAProblemReturningContractSummaries:
        "Hubo un problema al devolver los resúmenes de contratos.",
    thereWasAProblemReturningCourses: "Hubo un problema al devolver los cursos.",
    thereWasAProblemReturningEnrollmentCount:
        "Hubo un problema al devolver el recuento de inscripciones.",
    thereWasAProblemReturningEnrollments: "Hubo un problema al devolver las inscripciones.",
    thereWasAProblemReturningEventChangeLogs:
        "Hubo un problema al devolver los registros de cambios de eventos.",
    thereWasAProblemReturningEvents: "Hubo un problema al devolver los eventos.",
    thereWasAProblemReturningNotifications: "Hubo un problema al devolver las notificaciones.",
    thereWasAProblemReturningOrganizations: "Hubo un problema al devolver las organizaciones.",
    thereWasAProblemReturningProducts: "Hubo un problema al devolver los productos.",
    thereWasAProblemReturningProductTrainingContent:
        "Hubo un problema al devolver el contenido de entrenamiento del producto.",
    thereWasAProblemReturningProductUnits: "Hubo un problema al devolver las unidades de producto.",
    thereWasAProblemReturningRoles: "Hubo un problema al devolver las funciones.",
    thereWasAProblemReturningTheChecklist: "Hubo un problema al devolver la lista de verificación.",
    thereWasAProblemSavingTheZipPackage: "Hubo un problema al guardar el paquete Zip.",
    thereWasAProblemUpdatingTheAnnouncement: "Hubo un problema al actualizar el anuncio.",
    thereWasAProblemUpdatingTheChecklist:
        "Hubo un problema al actualizar la lista de verificación.",
    thereWasAProblemUpdatingTheContent: "Hubo un problema al actualizar el contenido.",
    thereWasAProblemUpdatingTheContract: "Hubo un problema al actualizar el contrato.",
    thereWasAProblemWithCreatingProductContent:
        "Hubo un problema con la creación de contenido del producto.",
    thereWasAProblemWithManagingContent: "Hubo un problema con la gestión de contenido.",
    thereWasAProblemWithProductContentManager:
        "Hubo un problema con el gerente de contenido del producto.",
    thereWasProblemSavingBulkEnrollments: "Hubo un problema al guardar inscripciones masivas.",
    thereWillBeNoImpactToTheseLearnersAndTheyWillRetainAccessToTheVersionTheyCompletedUntilItExpires:
        "Esto no afectará a estos alumnos y conservarán el acceso a la versión que completaron hasta que venza.",
    theseEventsAreForContractsOnly: "Estos eventos son solo para contratos.",
    theseEventsAreOpenToAllAndRunByNFPA:
        "Estos eventos están abiertos a todos (el público en general) y a cargo de la NFPA.",
    theseLearnersWillBeAbleToFinishThisProductAndRetainAccessToTheCurrentVersionAssignedToThemUntilItExpires:
        "Estos alumnos podrán finalizar este producto y conservar el acceso a la versión actual que se les asignó hasta que venza.",
    theSelectedFileTypeIsNotPermitted: "El tipo de archivo seleccionado no está permitido.",
    thisAttemptWasManuallyEnteredByNFPA: "Este intento fue ingresado manualmente por la NFPA.",
    thisContactInformationWillBeAvailableToEventParticipantsAs:
        "Esta información de contacto estará disponible para los participantes del evento como",
    thisContactInformationWillBeAvailableToEventParticipantsAsThePrimaryMethodToContactTheEventOrganizer:
        "Esta información de contacto estará disponible para los participantes del evento como método principal para comunicarse con el organizador del evento.",
    thisCourseHasNoContent: "Este curso no tiene contenido.",
    thisDataWillStayInSyncWithAnyChangesMadeToYourOrganizationsDefaultContactInfo:
        "Estos datos permanecerán sincronizados con cualquier cambio realizado en la información de contacto predeterminada de su organización.",
    thisEventHasNoRegistrationLimit: "ESTE EVENTO NO TIENE LÍMITES DE INSCRIPCIONES",
    thisEVENTHASNOREGISTRATIONLIMITS: "ESTE EVENTO NO TIENE LÍMITES DE INSCRIPCIONES",
    thisEventIsA: "Este evento es:",
    thisEventIsCurrentlyLive: "Este evento está actualmente en vivo.",
    thisEventWillBeCanceledAndParticipantsWillBeNotifiedThisActionCannotBeUndoneYoullBeAbleToAccessTheEventPageButWontBeAbleToEditTheEvent:
        "Este evento será cancelado y se notificará a los participantes. Esta acción no se puede deshacer. Podrá acceder a la página del evento, pero no podrá editarlo.",
    thisFileIncludesAHeaderRow: "Este archivo incluye una fila de encabezado. ",
    thisInformationIsSpecificToThisEventAndWillNotChangeOrSyncWithTheOrganizationsDefaultEventContactInformation:
        "Esta información es específica de este evento y no cambiará ni se sincronizará con la información de contacto del evento predeterminada de la organización.",
    thisInstructorCurrentlyHasNoApprovedToTeachTrainings:
        "Este instructor actualmente no está aprobado para dar entrenamientos.",
    thisInstructorCurrentlyHasNoAssignedRequiredTrainings:
        "Este instructor actualmente no tiene asignado ningún entrenamiento requerido.",
    thisInstructorCurrentlyHasNoCompletedTrainings:
        "Este instructor actualmente no ha completado ningún entrenamiento.",
    thisInstructorCurrentlyHasNoPastTrainings:
        "Este instructor actualmente no tiene entrenamientos anteriores.",
    thisNameIsUsedOnlyInTrainingManagement:
        "Este nombre se usa únicamente para la administración de entrenamientos.",
    thisProductAlreadyHasMultipleUnitsLearningPath:
        "Este producto ya tiene varias unidades. Ruta de aprendizaje",
    thisRegistrationEventWillBePublishedAdvertisedOnTheNFPACalendarWebsiteAndWillBeVisibleToTheGeneralPublic:
        "Este evento de registro se publicará (anunciará) en el sitio web del calendario de la NFPA y será visible para el público en general.",
    thisRegistrationEventWillNotBeIncludedOnTheNFPACalendarWebsite:
        "Este evento de registro no se incluirá en el sitio web del calendario de la NFPA.",
    thisTrainingExpiredWithIncompleteItems:
        "Este entrenamiento venció con elementos incompletos. Revise su Resumen de entrenamientos para obtener más detalles.",
    thisTrainingHasBeenCanceled: "Este entrenamiento se ha cancelado.",
    thisUnitIsOptional: "Esta unidad es opcional.",
    thisWillImmediatelyRemoveThemFromThisTraining:
        "Esto los eliminará inmediatamente de este entrenamiento.",
    thursdayAbbreviated: "Jue",
    time: "Tiempo",
    timezone: "Zona horaria",
    toEnrollInAllOtherTrainingsNavigateToTheIndividualContractOrILTEventsEnrollmentSection:
        "Para inscribirse en todos los demás entrenamientos, navegue hasta la sección de inscripción del contrato individual o del evento ILT.",
    topic: "Tema",
    topicColon: "Tema:",
    topics: "Temas",
    topicSelection: "Selección del tema",
    total: "Total",
    totalCEUs: "Total de CEU:",
    totalClassTime: "Tiempo de clase total",
    totalEnrollmentCountText: "Total: {{enrollmentCountText}}",
    totalEnrollmentsTotalLearnersParticipated:
        "Total de inscripciones/total de alumnos que participaron",
    totalImported: "Total importado",
    totalNumberOfCeusAwarded: "Cantidad total de CEU otorgadas",
    totalNumberOfLearnersAwardedCeus: "Cantidad total de CEU otorgadas a alumnos",
    totalTrainingsConducted: "Total de entrenamientos realizados",
    totalUsedEnrollmentCount: "Total: {{usedEnrollmentCount}}",
    trainingAccessExp: "Vto. de acceso al entrenamiento",
    trainingAccessExpiration: "Vencimiento de acceso al entrenamiento",
    trainingAccessExpirationDate: "Fecha de vencimiento de acceso al entrenamiento",
    trainingAccessExpirationHistory: "Historial de vencimiento de acceso al entrenamiento",
    trainingAchievements: "Logros de entrenamiento",
    trainingManager: "Gerente de entrenamiento",
    trainingMaterialsOutlineReport: "Informe general de materiales de entrenamiento",
    trainingName: "Nombre del entrenamiento",
    trainingOverview: "Descripción general del entrenamiento",
    trainingRequired: "Entrenamiento requerido",
    trainings: "Entrenamientos",
    trainingStatistics: "Estadísticas del entrenamiento",
    trainingSummary: "Resumen de la capacitación",
    trainingSummaryILT: "Resumen del entrenamiento (ILT)",
    trainingType: "Tipo de entrenamiento",
    trainTheTrainer: "Entrenar al entrenador",
    travelPreferences: "Preferencias de viaje",
    tuesdayAbbreviated: "Mar",
    twelcomeLearnerFirstName: "{{twelcome}}, {{learnerFirstName}}",
    twelcomeUserFirstName: "{{twelcome}}, {{userFirstName}}",
    type: "Escribir",
    typeSearchByCourseNameOrId: "Escribir para buscar por nombre o identificación del curso",
    typeToSearchByContractNameOrID: "Escribir para buscar por nombre o identificación del contrato",
    typeToSearchByContractNumber: "Escribir para buscar por número de contrato",
    typeToSearchByCourseNameOrID: "Escribir para buscar por nombre o identificación del curso",
    typeToSearchByEventNameOrID: "Escribir para buscar por nombre o identificación del evento",
    typeToSearchByName: "Escribir para buscar por nombre",
    typeToSearchByNameOrId: "Escribir para buscar por nombre o identificación",
    typeToSearchByOppNumber: "Escribir para buscar por número de OPP",
    typeToSearchByProductNameOrID: "Escribir para buscar por nombre o identificación del producto",
    typeToSearchByProductNameOrId: "Escribir para buscar por nombre o identificación del producto",
    typeToSearchByTrainingName: "Escribir para buscar por nombre de entrenamiento",
    typeToSearchProducts: "Escribir para buscar productos",
    unableToCreateEmulationToken: "No se puede crear el token de emulación.",
    unableToLoginAsSubscriber: "No se puede iniciar sesión como suscriptor.",
    unassigned: "No asignado",
    unassignTraining: "Desasignar entrenamientos",
    unauthorized: "No autorizado",
    unit: "Unidad",
    unitAbbreviated: "U",
    unitDescription: "Descripción de la unidad",
    unitDetails: "Detalles de la unidad",
    unitEnrollmentUnitSortOrder: "Unidad {{enrollmentUnitSortOrder}}",
    unitName: "Nombre de la unidad",
    unitSettings: "Configuración de la unidad",
    unitUnitSortOrder: "UNIDAD {{unitSortOrder}}",
    unitUpdatedSuccessfully: "Unidad actualizada correctamente",
    unknown: "Desconocido",
    upcomingEvents: "Próximos eventos",
    updateContent: "Actualizar contenido",
    updatedbygetfirstandlastnameMessage: "{{updatedByGetFirstAndLastName}} | {{message}}",
    updateExpiration: "Actualizar vencimiento",
    updateExpirationDate: "Actualizar fecha de vencimiento",
    updateYourContactInformationOnNfpaOrg: "Actualice su información de contacto en www.nfpa.org.",
    updateYourLocationOnNfpaOrg: "Actualice su ubicación en www.nfpa.org.",
    upload: "Cargar",
    uploadACSVFile: "Carga archivo CSV:",
    uploadAProfilePicture: "Cargar una foto de perfil",
    uploadResumeOrCV: "Cargar currículum o CV",
    useDefault: "Usar valor predeterminado",
    userHasBeenAddedSuccessfully: "El usuario se ha agregado correctamente.",
    userHasBeenUpdatedSuccessfully: "El usuario se ha actualizado correctamente.",
    userManagement: "Administración de usuarios",
    userPreferredLanguage: "Idioma preferido del usuario",
    userRoles: "Funciones de usuario",
    users: "Usuarios",
    useThisTemplate: "Usar esta plantilla",
    utah: "Utah",
    vermont: "Vermont",
    version: "Versión",
    versionVersionNumber: "Versión {{versionNumber}}",
    veryLIKELY: "MUY PROBABLE",
    veryUNLIKELY: "MUY IMPROBABLE",
    viewAchievements: "Ver Logros",
    viewAENProvider: "Ver Proveedor AEN",
    viewAll: "Ver todo",
    viewAssessmentDetail: "Ver información de la evaluación",
    viewCatalog: "Ver Catálogo",
    viewContract: "Ver Contrato",
    viewCourse: "Ver Curso",
    viewENProvider: "Ver Proveedor AEN",
    viewEvent: "Ver Evento",
    viewOrganization: "Ver Organización",
    viewProvider: "Ver Proveedor",
    viewResults: "Ver resultados",
    viewSummary: "Ver Resumen",
    viewTheChangeHistory: "Ver el historial de cambios",
    viewTheVersionHistory: "Ver el historial de versiones",
    viewTraining: "Ver Capacitación",
    viewUnit: "Ver Unidad",
    viewUserInfo: "Ver Información del usuario",
    viewYourProfile: "Ver Mi perfil",
    virginia: "Virginia",
    wantToTeachMoreTopics: "¿Desea enseñar más temas?",
    warning: "Advertencia",
    washington: "Washington",
    wednesdayAbbreviated: "Mié",
    welcome: "Bienvenido",
    WeNoticedThisIsAContractInPersonEvent:
        "Observamos que este es un evento presencial por contrato. Use esta opción para configurar todo el día cuando se desconozcan los horarios de las sesiones.",
    westVirginia: "West Virginia",
    wisconsin: "Wisconsin",
    withdraw: "Retirar",
    withdrawALearner: "Retirar a un alumno",
    withdrawEnrollmentSuccessful: "Retiro de la inscripción exitoso",
    withdrawLearner: "Retirar alumno",
    withdrawn: "Retirado",
    wouldYouLikeThisEventPublishedInTheNFPACalendar:
        "¿Le gustaría que este evento se publicara en el Calendario de la NFPA?",
    wyoming: "Wyoming",
    yes: "Sí",
    yesCancel: "Sí, cancelar",
    yesChange: "Sí, cambiar",
    yesIWantToCancelThisEventAndUnderstandThisActionCannotBeUndone:
        "Sí, quiero cancelar este evento y entiendo que esta acción no se puede deshacer. ",
    yesLeave: "Sí, salir",
    yesRemove: "Sí, eliminar",
    youAreDeactivatingTheProductPleaseReviewCarefullyBeforeDeactivating:
        "Está por desactivar el producto. Revíselo detenidamente antes de desactivarlo.",
    youAreEditingTheCourseDetails: "Está editando los detalles del curso.",
    youAreEmulating: "Está emulando:",
    youAreGoingToCreateVersionVersionNumber: "Está por crear la versión {{versionNumber}}",
    youAreGoingToEditTheCourseDetails: "Está por editar los detalles del curso.",
    youAreGoingToEditVersionVersionNumber: "Está por editar la versión {{versionNumber}}.",
    youAreLoggedInWithYourIdentityRoleNameRole:
        "Ha iniciado sesión con su función {{identityRoleName}}.",
    youAreNowEditingALiveEvent: "Ahora está editando un evento en vivo.",
    youAreViewingADraftEvent: "Está viendo un evento borrador.",
    youAreViewingAnArchivedCourse: "Está viendo un curso archivado.",
    youAreWithdrawingEnrollmentName: "Está retirando {{enrollmentName}}.",
    youCanAddAShortCancellationMessageThisMessageWillBeCommunicatedToParticipantsAndDisplayedInTheLMS:
        "Puede agregar un breve mensaje de cancelación. Este mensaje se comunicará a los participantes y se mostrará en el LMS.",
    youCanEditTheCoursesContent: "Puede editar el contenido del curso.",
    youDoNotHaveAccessToThisPageBasedOnYourNFPALMSRoleTypeDisplayNamescurrentRoleProfile:
        "No tiene acceso a esta página según su perfil del LMS {{RoleTypeDisplayNamescurrentRole}} de la NFPA.",
    youHaveBeenAddedOnBehalfOfContractOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "Ha sido agregado en nombre de {{contractOrganizationName}}. Sus datos pueden compartirse con esta organización.",
    youHaveBeenAddedOnBehalfOfEventOrganizationNameYourDataMayBeSharedWithThisOrganization:
        "Ha sido agregado en nombre de {{eventOrganizationName}}. Sus datos pueden compartirse con esta organización.",
    youHaveExceededTheRecommendedAmountOfClassTime:
        "Ha superado la cantidad de tiempo de clase recomendada.",
    youHaveNoCurrentAnnouncements: "No cuenta con anuncios actuales.",
    youHaveUnsavedChanges: "Tiene cambios sin guardar.",
    youMustChangesToZIPPackageAreNotAvailableWhenEditingTheCurrentCourseVersionToChangeTheZIPPackageYouMustCreateANewCourseVersion:
        "No puede realizar cambios en el paquete ZIP al editar la versión actual del curso. Para cambiar el paquete ZIP, debe crear una nueva versión del curso.",
    youMustContentChangesWillBeAppliedToAllLearnersOnThisVersionRegardlessOfCompletionStatusAndWillNotImpactOrResetALearnersProgress:
        "Los cambios se aplicarán a todos los alumnos en esta versión, independientemente del estado de finalización, y no afectarán ni restablecerán el progreso del alumno.",
    youMustUploadACSVFile: "Debe cargar un archivo CSV.",
    youMustUploadAJPGSmallerThan3MB: "Debe cargar un JPG menor a 3 MB.",
    youMustUploadAFileTypeSmallerThanXMB: "Debe cargar un {{FileTypes}} menor a {{MaxFileSize}}MB.",
    youMustUploadANewZIPPackage: "Debe cargar un nuevo paquete ZIP.",
    youMustUploadAPDFSmallerThan5MB: "Debe cargar un PDF menor a 5 MB.",
    youMustUploadAZIPFile: "Debe cargar un archivo ZIP.",
    youMustUploadAZipFile: "Debe cargar un archivo zip.",
    yourCertificateOfCompletionWith: "Su certificado de finalización con CEUs",
    yourOrganization: "Su organización",
    yourProfileAndInformationWillBeSharedWithLearnersAsAPointOfContact:
        "Su perfil e información se compartirán con los alumnos como punto de contacto.",
    yourReportIsRunningYoullReceiveANotificationWhenYourReportIsReady:
        "Su informe se está ejecutando. Recibirá una notificación cuando su informe esté listo.",
    yourResponses: "Sus respuestas",
    yourRole: "Su función",
    youveBeenGrantedAnExceptionToExceedMaxCreditHoursHoursForYourEvent:
        "Se le ha concedido una excepción para superar {{maxCreditHours}} horas para su evento.",
    youveEnteredCEUs: "Ha ingresado 0.0 CEU.",
    youveEnteredCeusCEUs: "Ha ingresado {{ceus}} CEU.",
    youWereWithdrawnFromThisTrainingOn: "Ha sido retirado de este entrenamiento el {{date}}.",
    youWillHaveTheChoiceOnHowToApplyThisNewVersionToLearnersThatAreCurrentlyInProgressChoosingToApplyThisVersionWillResultInResettingTheirProgress:
        "Tendrá la opción de aplicar esta nueva versión a los alumnos que están actualmente en curso. Si elige aplicar esta versión, se restablecerá su progreso.",
    zipCodeIsRequired: "Se requiere código postal.",
    zipCodeSlashPostalCode: "Código postal",
    zipPackage: "Paquete zip",
};

const spanishTranslations: CultureResources = {
    ...professionalSpanishTranslations,
    ...placeholderSpanishTranslations,
};

// #endregion Translations

// -------------------------------------------------------------------------------------------------
// #region Culture
// -------------------------------------------------------------------------------------------------

const Spanish: Culture<CultureResources> = cultureFactory(
    CultureCodes.SPANISH,
    spanishTranslations
);

// #endregion Culture

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export { Spanish };

// #endregion Exports
